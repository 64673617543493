@import "../functions/_util.scss", "../_settings.scss";
@import "./checkout-thank-page.scss";

.checkout-block {
    border-bottom: rem-calc(1px) solid #ededee;
    margin-bottom: rem-calc(30px);
    padding-bottom: 0.6rem;
}

.checkout-usertype {
    padding-bottom: 0.6rem;
}
.checkout__button-summary {
    cursor: pointer;
}
.checkout__button-summary:disabled:hover {
    cursor: default;
}
.checkout-block-title {
    line-height: 1;
}

.checkout-block-content-inner {
    display: inline-block;
    vertical-align: top;
}

.checkout-result {
    font-size: $font-size-xmedium;
    margin-bottom: rem-calc(30px);
    line-height: 1;
}

.checkout-bonus-result {
    padding: rem-calc(7px) 0 0 0;
}

.checkout-agree {
    margin-bottom: rem-calc(30px);
}

.checkout-result-text, .checkout-cart-result-price {
    color: #fff;
}

.checkout-result-price {
    font-weight: bold;
}

.checkout-cart-wrap {
    position: relative;
}

.checkout-cart {
    border-radius: $border-radius-base;
    width: 100%;
}

.checkout-cart-title {
    padding: rem-calc(15px) rem-calc(25px);
    margin: 0;
    border-bottom: rem-calc(1px) #dbdbdb solid;
}

.checkout-cart-items, .checkout-cart-generated-field {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
    box-sizing: border-box;
}
.checkout-cart-items__container--scrollable {
    overflow: auto;
    max-height: 279px;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .4);
    }

    &::-webkit-scrollbar-track {
        background-color: #f8f8f8;
        border-radius: 8px;
    }
}

.checkout-cart-item {
    padding: rem-calc(10px) 0;
}

.checkout-cart-item-row {
    display: table-row;

    & + .checkout-cart-item-row .checkout-cart-item-cell {
        border-top: rem-calc(1px) #e9e9e9 solid;
    }
}

.checkout-cart-item-cell {
    display: table-cell;
    vertical-align: middle;
}

.checkout-cart-item-cell-name {
    padding-left: rem-calc(25px);
}

.checkout-cart-item-cell-value {
    padding-right: rem-calc(25px);
    white-space: nowrap;
    text-align: right;
}

.checkout-cart-generated-field {
    border-top: rem-calc(1px) #e9e9e9 solid;
    padding: rem-calc(10px) rem-calc(25px);
    display: table;
    width: 100%;
}

.checkout-cart-generated-row {
    display: table-row;
}

.checkout-cart-generated-cell {
    display: table-cell;
    vertical-align: middle;
}

.checkout-cart-generated-name {
}

.checkout-cart-generated-value {
    text-align: right;
}

.checkout-cart-result {
    font-size: $font-size-xmedium;
    padding: rem-calc(10px) rem-calc(25px);
}

.checkout-cart-result-price {
    font-weight: bold;
}

.checkout-cart-verify {
    padding: rem-calc(10px) rem-calc(25px);
}

.checkout-cart-oneclick {
    border-top: rem-calc(1px) #e9e9e9 solid;
    padding: rem-calc(10px) rem-calc(25px);
}

.checkout-cart-oneclick-wrap {
    font-size: $font-size-xmedium;
}

.checkout-variant-more {
    margin-left: rem-calc(22px);
}

.checkout-usertype-label {
    margin-right: $margin-value-base;
    margin-bottom: 0.6rem;
}

.checkout-cart-item-count {
    font-size: $font-size-premedium;
    padding-bottom: 0.2rem;
}

.checkout-cart-item-properties {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    font-size: $font-size-premedium;
}

.checkout-cart-item-property-row {
    display: table-row;
}

.checkout-cart-item-property-name,
.checkout-cart-item-property-value {
    display: table-cell;
    vertical-align: middle;
    font-size: $font-size-small;
}

.checkout-cart-item-property-name {
    padding: rem-calc(5px) rem-calc(5px) 0 rem-calc(5px);
}

.checkout-cart-item-property-value {
    padding: rem-calc(5px) 0 0 rem-calc(5px);
}

.checkout-not-confirmed {
    font-size: rem-calc(17px);
    padding: 0 0 rem-calc(15px) 0;
}

.billing-form {
    background: #fff;
    padding: $vertical-interval-small $horizontal-interval-small;
    border-radius: $border-radius-small;
}

.checkout-tax {
    display: none;
}
