.toolbar-top {
    background-color: white;
}
.toolbar-top-item {
    padding: 0;
    font-size: 13px;
    color: #000000;
}

.toolbar-top-link-alt.link-dotted-invert, .toolbar-top-link.link-dotted-invert, .toolbar-top-item a.link-dotted-invert {
    border-bottom-color: #000000;
}

.toolbar-top-link-alt, .toolbar-top-link, .toolbar-top-item a {
    color: #000000;
}

.toolbar-top-link-alt {
    margin: 0; 
}