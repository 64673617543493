.cart-mini-scrollbar {
    max-height: 708px;
}

.cart-mini-scrollbar-block {
    padding: 0.3125rem 0 1.3125rem 0;
}

@media (max-height: 1100px) {
    .cart-mini-scrollbar {
        max-height: 666px;
    }
}

@media (max-height: 1050px) {
    .cart-mini-scrollbar {
        max-height: 590px;
    }
}

@media (max-height: 1000px) {
    .cart-mini-scrollbar {
        max-height: 550px;
    }
}

@media (max-height: 950px) {
    .cart-mini-scrollbar {
        max-height: 510px;
    }
}

@media (max-height: 900px) {
    .cart-mini-scrollbar {
        max-height: initial;
    }
}

@media (max-height: 550px) {
    .cart-mini-result-block-custom {
        position: relative;
        margin: 5px 0px 10px 0px;
    }
}
/*@media (max-height: 1000px) {
    .cart-mini-scrollbar {
        max-height: 560px;
    }
}

@media (max-height: 950px) {
    .cart-mini-scrollbar {
        max-height: 530px;
    }
}


@media (max-height: 930px) {
    .cart-mini-scrollbar {
        max-height: 500px;
    }
}
    */
@media (max-height: 900px) {
    .cart-mini-scrollbar {
        max-height: 470px;
    }
}

@media (max-height: 870px) {
    .cart-mini-scrollbar {
        max-height: 440px;
    }
}

@media (max-height: 840px) {
    .cart-mini-scrollbar {
        max-height: 410px;
    }
}

@media (max-height: 810px) {
    .cart-mini-scrollbar {
        max-height: 380px;
    }
}

@media (max-height: 780px) {
    .cart-mini-scrollbar {
        max-height: 350px;
    }
}

@media (max-height: 750px) {
    .cart-mini-scrollbar {
        max-height: 320px;
    }
}

@media (max-height: 720px) {
    .cart-mini-scrollbar {
        max-height: 290px;
    }
}

@media (max-height: 690px) {
    .cart-mini-scrollbar {
        max-height: 260px;
    }
}

@media (max-height: 660px) {
    .cart-mini-scrollbar {
        max-height: 230px;
    }
}

@media (max-height: 630px) {
    .cart-mini-scrollbar {
        max-height: 200px;
    }
}

@media (max-height: 600px) {
    .cart-mini-scrollbar {
        max-height: 170px;
    }
}

@media (max-height: 570px) {
    .cart-mini-scrollbar {
        max-height: 140px;
    }
}

@media (max-height: 540px) {
    .cart-mini-scrollbar {
        max-height: 110px;
    }
}

@media (max-height: 510px) {
    .cart-mini-scrollbar {
        max-height: 80px;
    }
}

.cart-mini-amount-control .input-small {
    padding: 0.1625rem 0.5625rem;
}

.cart-mini-amount-control .spinbox__arrows {
    padding-top: 7px;
}