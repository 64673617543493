/* https://www.smashingmagazine.com/2014/02/making-embedded-content-work-in-responsive-design/ */

iframe-responsive {
    display: block;
    width: 100%;
    height: 100%;
}

.iframe-responsive__container-wrap {
    width: 100%;
    height: 100%;
}

.iframe-responsive__container {
    position: relative;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

    .embed-container iframe, .embed-container object, .embed-container embed, .embed-container video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

/*.iframe-responsive__container iframe {
    display: block;
    width: 100%;
    position: relative;
}*/

.iframe-responsive__container .iframe-responsive__custom-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    border-radius: 50%;
    /*border: 11px solid #fff;*/
    background-color: #fff;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iframe-responsive__custom-play-wrap {
    pointer-events: none; /*Чтобы клик происходил по видео*/
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;
    background-clip: border-box;
    background-color: rgba(0, 0, 0, 0);
    background-origin: padding-box;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 100%;
    background-repeat: no-repeat;
}

.iframe-responsive__custom-play-icon {
    width: 52px;
    height: 52px;
}

.modal-video-icon {
    margin-right: 24px;
}

@media (max-width: 30em) {
    .iframe-responsive__container .iframe-responsive__custom-play {
        border: 2px solid #fff;
    }
    .iframe-responsive__custom-play-icon {
        width: 72px;
        height: 72px;
    }
}

.opacity {
    opacity: 0;
}

.iframe-responsive__container-upload video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.embed-container.iframe-responsive__container-upload {
    /*padding: 0;*/
}

.iframe-responsive__container--image {
    display: block !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.iframe-responsive__container--image {
    background-repeat: no-repeat;
    background-size: cover;
}


/*.iframe-responsive__container + iframe-responsive__container--image {
    display: none !important;
}

@media(min-width: 48em) {
    .iframe-responsive__container + iframe-responsive__container--image {
        display: block !important;
    }
}*/