#share42 {
    & > a {
        width: 32px !important;
        height: 32px !important;
        background-image: url(../../images/socials/socials.svg) !important;
        background-repeat: no-repeat;
        background-size: 32px !important;
    }

    [data-count="fb"] {
        background-position: 0 0 !important;
    }

    [data-count="vk"] {
        background-position: 0 -32px !important;
    }

    [data-count="odkl"] {
        background-position: 0 -64px !important;
    }

    [data-count="twi"] {
        background-position: 0 -160px !important;
    }
}
