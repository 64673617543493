﻿@import "../functions/_util.scss", "../_settings.scss";

.checkout-thank-you-page-action,
.checkout-thank-you-page-order-details {
    margin-top: 35px;
}

.checkout-thank-you-page-order-details {
    padding: 0 10px;
}

.checkout-thank-you-page__social-link {
    font-size: 0;
    line-height: 0;
    margin: 0 10px;
}

.checkout-success-title {
    margin-top: 35px;
}

.checkout-success-bonus-card-block {
    margin-top: 70px;
    margin-bottom: 70px;
}

@media(min-width:48em) {
    .checkout-thank-you-page-order-details {
        padding: 0;
    }

    .checkout-cart-col {
        padding-left: $margin-value-base + $padding-base;
    }
}
