.social {
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #7c7c7c;
    overflow: hidden;
    font-size: 22px;
    margin: 0 5px;
    text-decoration: none;
    background: none;
    transition: .3s;

    &:before {
        margin-right: 0;
    }

    &:hover {
        color: #494949;
        text-decoration: none;
        background: none;
        transition: .3s;
    }

    &--vkontakte {

        &:before {
            width: auto;
        }
    }

    &--facebook {
    }

    &--youtube {
    }

    &--twitter {
    }

    &--instagram {
    }

    &--telegram {
    }

    &--odnoklassniki {
    }
}
