@import "../_settings.scss";

.product-color {
    display: inline-block;
    vertical-align: middle;
    text-indent: 100%;
    border-radius: $border-radius-small;
    margin: 0 rem-calc(5px);
    overflow: hidden;
}
