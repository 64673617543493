.site-head-phone {
    font-size: 17px;
    font-family: "OpenSansBold";
}

.site-head-cart {
    margin-bottom: 0;
    position: relative;
}

.site-head-userid {
    position: absolute;
    top: -16px;
    left: -40px;
    width: 150px;
}