@use "sass:math";

@import "../../../../styles/_settings.scss", "../../../../styles/functions/_util.scss";

.payment-item {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
}
.payment-item-col{
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
.payment-item-icon {
    flex-shrink: 0;
    & > img {
        cursor: pointer;
        vertical-align: middle;
        object-fit: contain;
    }
}

.payment-item-info {
    padding: 0 0 0 $padding-base;
}

.payment-item-title,
.payment-item-description {
    display: block;
    line-height: 1.2;
    cursor: pointer;

    &--flex {
        display: flex;
    }
}

.payment-item-description {
    font-size: 0.75rem;
    padding-top: rem-calc(5px);
}

.payment-item-title {
    font-size: $font-size-medium;
    line-height: 1;

    &--discount {
        display: inline-block;
        padding-top: 3px;
        padding-bottom: 3px;
        color: red;
    }
}

.payment-item-more {
    font-size: $font-size-medium;
}

.payment-control {
    padding-top: rem-calc(10px);
    font-size: $font-size-medium;
}

.payment-progress {
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        background: rgba(255,255,255, .5);
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 10;
    }
}

.payment-progress-after:after {
    left: 50%;
    top: 50%;
    font-size: rem-calc(35px);
    margin: math.div(rem-calc(-35px),2) 0 0 math.div(rem-calc(-35px), 2);
}

.payment-empty {
    padding: rem-calc(40px) 0;
}

.payment-custom-option {
    display: none;
}
.payment-item-active .payment-custom-option{
    display: block;
}