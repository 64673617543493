body, button, input, textarea {
    color: #333e48;
    font-family: "OpenSans";
    font-display: swap;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSans";
    src: url("../fonts/OpenSansBold/OpenSansBold.woff") format("woff");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "OpenSansBold";
    src: url("../fonts/OpenSansBold/OpenSansBold.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSansLight";
    src: url("../fonts/OpenSansLight/OpenSansLight.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "OpenSansRegular";
    src: url("../fonts/OpenSansRegular/OpenSansRegular.woff") format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

.adv-popover-overlay {
    z-index: 100;
}

.adv-popover.adv-popover-position-bottom.adv-popover-fixed {
    z-index: 110;
}

.toolbar-top {
    border-bottom: 1px solid #ddd;
    padding: 1.05em 0;
}

.toolbar-top-item .toolbar-top-link-alt:after {
    content: '|';
    color: #cad5d5;
    display: inline-block;
    margin: 0 1em;
}

.toolbar-top-item .toolbar-top_city:after {
    content: '|';
    color: #cad5d5;
    display: inline-block;
    margin: 0 1em;
}

.toolbar-top-item .toolbar-top-link-alt:last-child:after {
    display: none;
}

.toolbar-top-item .icon_topbar_top {
    margin-right: 6px;
    width: 14px;
    height: 14px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.toolbar-top-item .icon_topbar_top:before {
    content: '';
    background: url(../images/user.png);
    width: 13px;
    height: 13px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.toolbar-top-item .icon_topbar_top-login {
    margin-right: 6px;
    width: 14px;
    height: 14px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.toolbar-top-item .icon_topbar_top-login:before {
    content: '';
    background: url(../images/login.png);
    width: 13px;
    height: 13px;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.toolbar-top-link-alt:hover, .toolbar-top-link-alt:active, .toolbar-top-link:hover, .toolbar-top-link:active, .toolbar-top-item a:hover, .toolbar-top-item a:active {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.toolbar-top-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.toolbar-top-item .toolbar-top-link-alt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.toolbar-top-item .toolbar-top-link-alt:hover {
    text-decoration: none;
}

.toolbar-top_city {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.toolbar-top_city_icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 6px;
}

.toolbar-top_city_icon .ec-map-pointer {
    font-size: 18px;
    position: relative;
    top: 2px;
}

.site-head-inner {
    padding: 20px 0;
}

.menu-header {
    text-align: center;
    font-family: "OpenSansBold";
    font-size: 15px;
}

.site-menu-row {
    border-radius: 0;
    padding: .358em 1rem;
}

.site-menu-row .site-head-search-form {
    margin: 0;
}

.site-menu-row .site-head-search-form .site-head-search-input-wrap {
    padding-right: 0;
    padding-left: 0;
}

.site-menu-row .site-head-search-form .site-head-search-input-wrap .site-head-search-input {
    border-top-left-radius: 2.467em;
    border-bottom-left-radius: 2.467em;
    background: #fff;
    line-height: 1.34em;
    padding: 1.01em 1.134em 1.01em 2.134em;
    height: 50px;
    margin-bottom: 0;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.site-menu-row .site-head-search-form .site-head-search-btn-wrap {
    padding-right: 0;
    padding-left: 0;
}

.site-menu-row .site-head-search-form .site-head-search-btn-wrap .site-head-search-btn {
    background-color: #333e48;
    border-color: #333e48;
    color: #fff;
    border-top-right-radius: 2.467em;
    border-bottom-right-radius: 2.467em;
    height: 50px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.site-menu-row .site-head-search-form .site-head-search-btn-wrap .site-head-search-btn:hover {
    background-color: black;
    border-color: black;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.site-head-search-form .site-head-search-btn-wrap .ec-search {
    font-size: 20px;
}

.site-head-search-btn .icon-search-block {
    display: block;
    color: white;
    font-size: 19px;
}

.cart-mini-main {
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.cart-mini-main:hover {
    text-decoration: none;
}

.cart-mini-main .cart-mini-main-count {
    position: absolute;
    bottom: -8px;
    right: -6px;
    border-radius: 100%;
    background: #333e48;
    width: 20px;
    height: 20px;
    line-height: 1px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "OpenSansBold";
}

.head-compare {
    position: relative;
    width: 22px;
    height: 20px;
    display: block;
}

.head-compare {
    background: url(../images/reload.svg);
    fill: #374146;
    transition: .3s;
}

.head-compare svg {
    width: 18px;
    height: 20px;
    transition: .3s;
}

.head-compare .ec-compare:hover, .head-wishlist .ec-favorites:hover, .cart-mini-main .ec-shopping-bag:hover {
    color: black;
}

.head-compare .ec-compare {
    font-size: 23px;
}


.head-wishlist .ec-favorites {
    font-size: 23px;
    transition: .3s;
}

.cart-mini-main .ec-shopping-bag {
    font-size: 23px;
}

.head-compare .compare-header-block-count {
    position: absolute;
    bottom: -5px;
    right: -3px;
    border-radius: 100%;
    background: #333e48;
    width: 20px;
    height: 20px;
    line-height: 1px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "OpenSansBold";
}

.head-wishlist {
    position: relative;
    width: 22px;
    height: 20px;
    display: block;
}

.head-wishlist .wishlist-header-block-count {
    position: absolute;
    bottom: -5px;
    right: -3px;
    border-radius: 100%;
    background: #333e48;
    width: 20px;
    height: 20px;
    line-height: 1px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "OpenSansBold";
    display: none;
}

.site-head-phone-col_icon:before {
    content: '';
    background: url(../images/support.svg);
    width: 50px;
    height: 46px;
    display: block;
    background-repeat: no-repeat;
    position: relative;
    background-size: contain;
    background-position: center;
}

.site-head-phone-col {
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
    max-width: 23%;
}

.site-head-phone-col-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.site-head-phone-col-flex .site-head-phone-col-flex-icon {
    padding-right: 20px;
}

.site-head-phone-col-flex .site-head-phone-col-flex-icon .ec-support {
    font-size: 55px;
}

.menu-dropdown-root {
    font-family: "OpenSansBold";
}

.menu-dropdown-root .icon-down-open-after-abs::after {
    right: initial;
    margin-left: 25px;
}

.toolbar-top-container {
    padding-right: 0;
    padding-left: 0;
}

.menu-dropdown-list {
    padding: 10px 0px 10px 10px;
    border-top: none;
    border-width: 2px;
    border-radius: .625rem;
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.menu-dropdown-list:hover {
    border-bottom-right-radius: 0;
}

.menu-dropdown-item {
    padding-right: 10px;
}

.footer-bottom-level {
    background-color: #eaeaea;
}

.site-footer-top-level {
    background-color: #f8f8f8;
}

.site-footer-wrap .subscribe-block {
    padding: 0.9rem 9999px 0.9rem 9999px;
    margin: 0 -9999px 2.5rem -9999px;
    margin-bottom: 0;
}

.site-footer-wrap .subscribe-block .subscribe-block-field {
    padding-right: 0;
    padding-left: 0;
}

.site-footer-wrap .subscribe-block .subscribe-block-field input {
    border-top-left-radius: 2.467em;
    border-bottom-left-radius: 2.467em;
    background: #fff;
    line-height: 1.34em;
    padding: 1.01em 2.134em;
    height: 50px;
    margin-bottom: 0;
    border: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.site-footer-wrap .subscribe-block .subscribe-block-button {
    padding-right: 0;
    padding-left: 0;
}

.site-footer-wrap .subscribe-block .subscribe-block-button .btn-subscribe {
    background-color: #333e48;
    border-color: #333e48;
    color: #fff;
    border-top-right-radius: 2.467em;
    border-bottom-right-radius: 2.467em;
    height: 50px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.site-footer-wrap .subscribe-block .subscribe-block-button .btn-subscribe:hover {
    background-color: black;
    border-color: black;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.subscribe-block-home .subscribe-block-text {
    font-size: 22px;
}

.subscribe-block-home .subscribe-block-text::before {
    content: "\76";
    font-family: font-electro;
    margin-right: 15px;
    font-size: 1.619em;
    vertical-align: middle;
}

.footer-call-us {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-call-us .footer-call-us-left {
    padding-right: 20px;
}

.footer-call-us .footer-call-us-left .ec-support {
    font-size: 65px;
}

.footer-call-us .footer-call-us-right {
    font-size: 22px;
}

.footer-menu-head {
    font-family: "OpenSansBold";
    color: #333e48;
    font-size: 16px;
    margin-bottom: 2.1em;
}

.footer-call-us .footer-call-us-right {
    font-size: 22px;
}

.footer-menu .footer-menu-list .footer-menu-item {
    font-size: 14px;
    font-family: "OpenSansRegular";
    margin: 0 0 0.7rem;
}

.recently-block-home .recently-title:after {
    content: ' ';
    width: 3.684em;
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
}

.recently-block-home .recently-title {
    font-size: 1.357em;
    line-height: .947em;
    margin-bottom: 1.842em;
    padding-bottom: 1.053em;
    position: relative;
    border-bottom: 1px solid #dadada;
}

.recently-block-home .price-discount {
    display: none;
}

.recently-block-home .recently-product-price .price-new {
    font-family: "OpenSansBold";
    font-size: 15px;
    color: #e00;
}

.recently-block-home .recently-product-price .price-old {
    font-family: "OpenSansBold";
    font-size: 11px;
    text-decoration: line-through;
}

.recently-block-home .recently-product-price .price-current {
    font-family: "OpenSansBold";
    font-size: 15px;
}
/*������ � ����*/
.widget_electro_features .features-list {
    padding: 30px;
    background-color: #fff;
}

.widget_electro_features {
    margin-bottom: 45px;
}

.features-list {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 2.5em;
    margin-left: 0;
    margin-right: 0;
}

.widget_electro_features .features-list .feature:first-child {
    padding-top: 0;
}

.widget_electro_features .features-list .feature {
    border-bottom: 1px solid #ddd;
    padding-top: 1.786em;
    padding-bottom: 1.786em;
}

.features-list .feature {
    padding-top: 2.143em;
    padding-bottom: 2.143em;
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
}

.widget_electro_features .features-list .media .media-left {
    padding-left: 0;
}

.media-left {
    padding-right: 10px;
}

.features-list .media-body {
    text-align: center;
    font-size: 1.071em;
    width: 75%;
}

.media-middle {
    vertical-align: middle;
}

.widget_electro_features .features-list .media i {
    font-size: 42px;
}

.widget_electro_features .features-list .media span {
    font-size: 42px;
}

.features-list .media-body {
    text-align: center;
    font-size: 1.071em;
}

.widget_electro_features .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.widget_electro_features .features-list .feature:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.widget_electro_features .feature-text-bold {
    font-family: "OpenSansBold";
}

.carousel-main-item img {
    border-radius: 0;
}

.footer-social-icons {
    margin-top: 40px;
}

.footer-social-icons .social-icons {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.footer-social-icons .social-icons .list-unstyled {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.footer-social-icons .social-icons li {
    display: inline-block;
    margin-right: 26px;
}

.footer-social-icons .social-icons a {
    color: #7c7c7c;
    font-size: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.footer-social-icons .social-icons a:before {
    font-size: 22px;
}

.footer-social-icons .social-icons a:focus, .footer-social-icons .social-icons a:hover {
    text-decoration: none;
    color: #494949;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.site-head-phone-col-flex .site-head-phone-col-flex-icon .ec-support {
    font-size: 55px;
}

.recently-block-home .recently-product-link:hover {
    text-decoration: none;
}

.recently-block-home .recentlyview-item {
    padding: 0px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 80px;
}

.recently-block-home .recentlyview-item .recentlyview-item-img {
    min-width: 75px;
    margin-right: 15px;
}


.recently-block-home .recentlyview-item .recentlyview-item-descr {
    min-height: 75px;
}

.recently-product-image-block {
    padding-right: 0 !important;
}

.recently-block-home .recently-product-name {
    height: 34px;
    overflow: hidden;
    line-height: 16px;
    margin-bottom: 0;
}

.recently-block-home .recently-product-data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*min-height: 75px;*/
}

.recently-block-home {
    margin-bottom: 45px;
    margin-top: 12px;
}

.news-block-home {
    margin-bottom: 45px;
}

.news-block-home .block-head {
    font-size: 1.357em;
    line-height: .947em;
    margin-bottom: 13px;
    padding-bottom: 1.053em;
    position: relative;
    border-bottom: 1px solid #dadada;
}

.news-block-home .block-head:after {
    content: ' ';
    width: 3.684em;
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
}

.news-block-home .carousel-main-wrap .carousel-inner .carousel-list {
    line-height: normal;
    font-size: 13px;
}

.news-block-home .news-block-item {
    display: inline-block;
    visibility: visible;
    white-space: normal;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 100%;
}

.news-block-home .news-block-item .news-item-img-col {
    text-align: center;
    margin-bottom: 10px;
}

.news-block-home .news-block-item .news-block-anno {
    font-family: "OpenSansBold";
    font-size: 15px;
    margin-top: 3px;
}

.news-block-home .carousel-nav {
    position: absolute;
    top: -36px;
    right: -3px;
    font-size: 10px;
}

.news-block-home .carousel-nav .carousel-nav-prev {
    left: -40px;
}

.news-block-home .carousel-nav .carousel-nav-next {
    right: -9px;
}

.news-block-home .carousel-nav .carousel-nav-next::after {
    display: none;
}

.news-block-home .carousel-nav .carousel-nav-next::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.details-row-related-block .carousel-nav .carousel-nav-next::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.details-row-related-block .carousel-nav .carousel-nav-next::after {
    content: '';
}

.details-row-related-block .carousel-nav .carousel-nav-prev::after {
    content: '';
}

.details-row-related-block .carousel-nav {
    position: absolute;
    top: -56px;
    right: -14px;
    font-size: 10px;
}

.details-row-related-block .carousel-nav .carousel-nav-prev {
    left: 19px;
}

.details-row-related-block .carousel-nav .carousel-nav-next {
    right: -9px;
}

.details-row-related-block .carousel-nav .carousel-nav-prev::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.news-block-home .carousel-nav .carousel-nav-next:hover::before {
    color: #333e48;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.news-block-home .carousel-nav .carousel-nav-prev::after {
    display: none;
}

.news-block-home .carousel-nav .carousel-nav-prev::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.news-block-home .carousel-nav .carousel-nav-prev:hover::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    color: #333e48;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


.products-view-block .products-view-item {
    border: none;
    border-radius: 0;
    padding: 20px 20px 17px 20px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.products-view-block .products-view-category .products-view-category-link {
    color: #878787;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.products-view-block .products-view-category .products-view-category-link:hover {
    color: #333;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.products-view-block .products-view-item .products-view-name {
    font-family: "OpenSansBold";
    font-size: 15px;
    line-height: 1.286em;
    margin: 0 0 10px 0;
}

.brand-txt .products-view-block .products-view-item>.products-view-name {
    display: none !important;
}

.products-view-block .products-view-item .products-view-name a {
    color: black !important;
}

.products-view-block .product-view-photos-wrap .products-view-photos {
    border-radius: 0;
}

.products-view-block .btn-product-view {
    font-size: .875rem;
    border-radius: 110px;
    border-width: 0;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    color: #333e48;
    background-color: #efecec;
    border-color: #efecec;
    transition: all .2s ease-in-out;
}

.products-view-block .btn-product-view:before {
    content: "\61";
    font-family: font-electro;
    color: #fff;
    font-size: 20px;
    right: 1px;
    position: relative;
    top: 1px;
}

.products-view-block .hover-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /*border-top: 1px solid #eaeaea;
    margin-top: 2px;*/
    position:relative;
}

.products-view-block .hover-area .hover-area-wishlist:before,
.products-view-block .hover-area .hover-area-compare:before {
    content: '';
    border-top: 1px solid #eaeaea;
    margin-top: 2px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.products-view-block .hover-area .wishlist-control, .products-view-block .hover-area .compare-control {
    color: #949494;
}

.products-view-block .hover-area .wishlist-control:hover, .products-view-block .hover-area .compare-control:hover {
    color: black;
}

.products-view-block .hover-area .hover-area-wishlist, .products-view-block .hover-area .hover-area-compare {
    width: 50%;
    text-align: center;
    padding-top: 4px;
}


.products-view-block .hover-area .hover-area-wishlist .ec-favorites, .products-view-block .hover-area .hover-area-compare .ec-compare {
    font-size: 16px;
}

.products-view-block .hover-area {
    visibility: hidden;
}

.products-view-block .products-view-item:hover .hover-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: visible;
}

.products-view-block .products-view-item:hover {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.products-view-block .products-view-item .products-view-pictures .products-view-photos-count {
    display: block;
    background-color: black !important;
}

.site-head-cart .total-cart-price {
    font-family: "OpenSansBold";
    font-size: 15px;
    padding-left: 7px;
}

.site-head-cart .cart-mini-main_cart {
    position: relative;
}

#menu-custom .main-menu-search {
    -ms-flex-preferred-size: 54.33333% !important;
    flex-basis: 54.33333% !important;
    max-width: 54.33333% !important;
    /*z-index: -10;*/
}

#menu-custom .main-menu-cart {
    -ms-flex-preferred-size: 20.66667% !important;
    flex-basis: 20.66667% !important;
    max-width: 20.66667% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 30px;
}

.site-body-main .slider-main-block .carousel {
    width: 100vw !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
}

.slider-main-block .carousel-inner {
    margin: 0 auto;
}

.products-tabs-specials-container-header {
    text-align: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #ddd;
    overflow: visible;
}

.products-tabs-specials-container-header .products-tabs-specials-container-header-item {
    display: inline-block;
    margin: 0 20px;
}

.products-tabs-specials-container-header .products-tabs-specials-container-header-item .products-tabs-specials-container-header-item-link {
    color: #333e48;
    font-size: 20px;
    padding-bottom: 17px;
    display: inline-block;
}

.products-tabs-specials-container-header .products-tabs-specials-container-header-item-active .products-tabs-specials-container-header-item-link {
    font-family: "OpenSansBold";
    position: relative;
}

.products-tabs-specials-container-header .products-tabs-specials-container-header-item-active .products-tabs-specials-container-header-item-link:before {
    content: ' ';
    height: 4px;
    width: 10px;
    display: block;
    position: absolute;
    bottom: -5px;
    left: 50%;
    border-radius: 0 0 10px 10px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.products-tabs-specials-container-header .products-tabs-specials-container-header-item-active .products-tabs-specials-container-header-item-link::after {
    content: ' ';
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
    width: 100%;
}

/*.products-view .products-view-block .products-view-item::after {
    content: '';
    border-right: 1px solid #eaeaea;
    display: block;
    position: absolute;
    top: 49%;
    right: 0;
    height: 76%;
    transform: translateY(-50%);
}*/

.owl-stage .owl-item.active .products-view-info::after {
    content: '';
    border-right: 1px solid #eaeaea;
    display: block;
    position: absolute;
    top: 49%;
    right: 0;
    height: 76%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.owl-stage .owl-item.last-active .products-view-info::after {
    display: none;
}

.products-view .products-view-block .products-view-item:hover::after {
    display: none;
}

.products-view .products-view-block:last-child .products-view-item:after {
    display: none;
}

.products-view-block .products-view-item .products-view-category {
    margin-bottom: 8px;
    font-size: 12px;
    font-family: "OpenSansLight";
}

.brand-txt .products-view-block .products-view-item>.products-view-category {
    display: none !important;
}

.products-view-info .carousel-colors {
    margin-top: 10px;
}

.hover-area .hover-area-wishlist .wishlist-state-add {
    position: relative;
    top: 2px;
}

.hover-area .block-exuding-row {
    padding: 0;
}

.compare-checkbox:checked ~ .custom-input-text .compare-text-added {
    position: relative;
    top: 2px;
}

.compare-checkbox:checked + .ec-compare {
    position: relative;
    top: 2px;
    color: red;
}

.wishlist-checkbox:checked + .ec-favorites {
    color: red;
}

.products-view-block .products-view-price .price-discount {
    display: none;
}

.products-view-block .products-view-price .price-new {
    color: #e00;
}

.products-view-block .products-view-price .price-old {
    font-size: 13px;
}

.products-tabs-specials-container {
    .owl-carousel-home-general .products-view-block {
        -ms-flex-preferred-size: 291.667px !important;
        flex-basis: 291.667px !important;
        max-width: 291.667px !important;
        width: 291.667px !important;
    }
}

.home_prodlist_custom .products-view-block {
    -ms-flex-preferred-size: 218.75px !important;
    flex-basis: 218.75px !important;
    max-width: 218.75px !important;
    width: 218.75px !important;
}

@media (max-width: 1200px) {
    .owl-carousel-home-general .products-view-block {
        width: initial !important;
    }

    .home_prodlist_custom .products-view-block {
        width: initial !important;
    }
}

.buy-in-time-block .buy-in-time-inner {
    border-radius: 1.214em;
    border: 2px solid transparent;
    padding: 1.429em 1.571em;
    position: relative;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-header {
    display: none;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-content .buy-in-time-picture-block .buy-in-time-discount {
    left: 0;
    top: 0;
    font-family: "OpenSansBold";
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-content .buy-in-time-picture-block .buy-in-time-discount .buy-in-time-discount-text {
    font-size: 12px;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block {
    text-align: center;
    width: 25%;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-name {
    font-family: "OpenSansBold";
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-name-link {
    color: #0062bd;
    margin-bottom: 25px;
    display: inherit;
    font-size: 17px;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-price-default {
    font-size: 0;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-price-default .price {
    font-size: 17px;
    color: #848484;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-price-today {
    color: #e00;
    font-size: 0;
    font-weight: normal;
    font-family: "OpenSansRegular";
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-price-today .price {
    font-size: 27px;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-price-block .buy-in-time-button-block .btn {
    background: #343f49;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    border-radius: 8px;
    width: 100px;
    height: 52px;
    padding: 0;
    /*display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;*/
    font-family: "OpenSansBold";
    position: absolute;
    top: 19px;
    left: 19px;
    padding: 7px 8px;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-content .buy-in-time-countdown-block {
    width: 30%;
    padding-right: 10px;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-content .buy-in-time-countdown-block .countdown-item-part {
    background: #e6e6e6;
    font-size: 22px;
    font-weight: normal;
    color: black;
}

.buy-in-time-block .buy-in-time-inner .buy-in-time-content .buy-in-time-countdown-block .countdown-item-label {
    color: #000000;
}

.buy-in-time-block.buy-in-time-block-vertical {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 45px;
}

.buy-in-time-block.buy-in-time-block-vertical .buy-in-time-inner .buy-in-time-content .buy-in-time-countdown-block {
    width: 100%;
    padding-right: 0;
    margin-bottom: 11px;
}

.buy-in-time-block.buy-in-time-block-vertical .buy-in-time-price-block {
    width: 100%;
}

.buy-in-time-block.buy-in-time-block-vertical .buy-in-time-price-block .buy-in-time-button-block {
    margin-top: 10px;
}

.buy-in-time-block.buy-in-time-block-vertical .buy-in-time-price-block .buy-in-time-button-block .btn {
    position: relative;
    width: 100%;
    height: auto;
    background: none;
    color: black;
    text-align: center;
    display: block;
    top: inherit;
    left: inherit;
}


.home_prodlist_custom .products-specials-header {
    border-bottom: 1px solid #dadada;
    margin-bottom: 25px;
}

.home_prodlist_custom .products-specials-header .h2 {
    font-size: 1.786em;
    line-height: 1.6em;
    display: inline-block;
    padding-bottom: .4em;
    position: relative;
    margin-bottom: 0;
}

.home_prodlist_custom .products-specials-header .h2:after {
    content: ' ';
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
    width: 100%;
}

.home_prodlist_custom .products-specials-block .products-view-tile {
    margin-left: 0;
    margin-right: 0;
}

.home_prodlist_custom .carousel-inner {
    padding: 3px;
}

.home_prodlist_custom .carousel-inner .product-view-carousel-list {
    overflow: visible;
}

.home_prodlist_custom {
    margin-top: 30px;
}

.owl-carousel .products-view-pictures img {
    display: inline-block !important;
    width: auto !important;
}

.owl-carousel .owl-stage-outer {
    width: 99.9%;
    padding: 3px 8px;
    margin-bottom: 0;
}

.owl-dots {
    text-align: center;
}

.owl-dots .owl-dot.active {
    width: 30px;
    height: 8px;
    border-radius: 3px;
}

.owl-dots .owl-dot {
    width: 8px;
    height: 8px;
    background-color: #bcbcbc !important;
    display: inline-block;
    border-radius: 50%;
    margin-left: 1em;
}

.owl-dots .owl-dot:focus {
    outline: none;
}


.carousel-dots .carousel-dots-item.carousel-dots-selected .carousel-main-dots-item {
    width: 30px;
    height: 8px;
    border-radius: 3px;
}

.carousel-dots .carousel-dots-item .carousel-main-dots-item {
    width: 8px;
    height: 8px;
    background-color: #bcbcbc !important;
    display: inline-block;
    border-radius: 50%;
    margin-left: 1em;
    box-shadow: none;
}

.carousel-dots {
    position: absolute;
    bottom: 11px;
    right: 0;
    left: 0;
}

.slider-main-block .carousel-main-wrap .carousel-nav {
    display: none;
}

.carousel-dots .carousel-dots-item .carousel-main-dots-item:focus {
    outline: none;
}

.tab-content {
    display: none;
}

.tab-content.active, .tab-content-active {
    display: block;
}

/*.products-tabs-specials-container .owl-item .carousel-colors .carousel-inner {
    width: 100% !important;
}*/

.products-tabs-specials-container .owl-item .carousel-colors .carousel-inner .color-viewer-list {
    width: 100% !important;
    text-align: center;
    overflow: visible;
}

.products-tabs-specials-container .owl-item .carousel-colors {
    padding: 0 !important;
}

.products-tabs-specials-container .owl-item .carousel-colors .carousel-inner .color-viewer-list .color-viewer-item {
    width: 28px !important;
    max-width: 28px !important;
    -ms-flex-preferred-size: 28px !important;
    flex-basis: 28px !important;
}

/*.products-tabs-specials-container .owl-item .carousel-colors .carousel-nav {
    display: none !important;
}*/

.products-specials-container .products-specials-block {
    margin-top: 20px;
}

.home_prodlist_custom .carousel {
    width: 100%;
}

.site-body-aside {
    padding-right: 15px;
}

.site-body-main {
    padding-left: 15px;
}

.products-specials-container .products-view-tile {
    margin-left: 0;
    margin-right: 0;
}

.products-tabs-specials-container, .home_prodlist_custom {

    .owl-nav {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        top: -82px;
        right: 0;
    }

    .owl-nav .owl-prev {
        color: #aeaeae !important;
        font-size: 21px !important;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
    }

    .owl-nav .owl-prev:focus, .owl-nav .owl-next:focus {
        outline: none;
    }

    .owl-nav .owl-next {
        color: #aeaeae !important;
        font-size: 21px !important;
        margin-left: 10px;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
    }

    .owl-nav .owl-prev:before {
        content: "\f104";
    }

    .owl-nav .owl-next:before {
        content: "\f105";
    }

    .owl-nav .owl-next:hover {
        color: #333e48 !important;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
    }

    .owl-nav .owl-prev:hover {
        color: #333e48 !important;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
    }

    .owl-nav span {
        display: none;
    }
}


#menu-custom .menu-dropdown .menu-dropdown-root {
    position: absolute;
    top: -32px;
    padding: 16px;
    left: 4px;
    font-size: 15px;
}

#menu-custom .menu-dropdown .menu-dropdown-root .menu-dropdown-root-text::after {
    top: 7px;
    position: relative;
}

#menu-custom .menu-dropdown .menu-dropdown-list {
    z-index: 1;
    top: 29px;
}

.carousel-logo {
    padding: 5px 30px !important;
    margin: 30px 0 !important;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
}

.carousel-logo:before {
    display: none;
}

.carousel-logo .carousel-nav .carousel-nav-prev {
    left: -25px;
    color: #d6d6d6;
}

.carousel-logo .carousel-nav .carousel-nav-next {
    right: -25px;
    color: #d6d6d6;
}

.carousel-logo .carousel-nav .carousel-nav-prev:hover {
    color: #869791;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.carousel-logo .carousel-nav .carousel-nav-next:hover {
    color: #869791;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.carousel-logo .carousel-logo-link img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-filter: gray;
    filter: gray;
    opacity: .5;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.carousel-logo .carousel-logo-link img:hover {
    -webkit-filter: grayscale(0);
    filter: none;
    opacity: 1;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.site-footer-top-level-inner {
    padding-bottom: 3.125rem !important;
}


.breadcrumbs--desktop {
    .breadcrumbs__item {
        display: flex;
    }
}

.breads .breads-item:not(:first-child) .breads-item-link {
    padding: 9px;
    background-color: #f5f5f5;
    border-radius: .357em;
    font-size: 14px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.breads .breads-item:not(:first-child) .breads-item-link:hover {
    background-color: #e8e8e8;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


.breads .breads-item:first-child {
    font-size: 14px;
}

.breads {
    margin-top: 33px;
    margin-bottom: 33px;
}

.breads .breads-item-fa {
    /*display: inline-block;*/
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.breads .breads-item-fa:after {
    content: "\f105";
    font-size: 14px;
    margin: 0 8px 0 12px;
}

.breads .breads-item a {
    font-family: "OpenSansRegular";
}

.breads .breads-item .breads-item-current {
    font-size: 14px;
    color: #334141;
}

.btn-product-view-preorder {
    color: #333e48;
    background-color: white;
    border-color: white;
    padding: 10px 10px;
    border-radius: 31px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-family: "OpenSansBold";
}

.products-view-block .products-view-item:hover .btn-product-view-preorder {
    color: black;
}

/*�������� ������*/
.product_pod_zakaz {
    margin: 10px 0;
}

.product-menu-categories-current {
    display: none;
}

.product-menu-categories .product-menu-categories-current {
    display: block;
    border-bottom: 1px solid #e2e3e4;
    padding: 12px 17px;
    font-size: 14px;
    color: black;
}

.product-menu-categories {
    border: 2px solid #ececec;
    border-radius: 6px;
    margin-bottom: 30px;
}

.product-menu-categories .menu-dropdown-treeview .menu-dropdown-item .menu-dropdown-sub-inner {
    display: block;
}

.product-menu-categories .menu-dropdown-treeview .menu-dropdown-item .menu-dropdown-sub-inner .menu-dropdown-sub-block-cats-only {
    padding-left: 68px;
    padding-bottom: 7px;
}

.product-menu-categories .menu-dropdown-treeview .menu-dropdown-item .menu-dropdown-link-wrap .menu-dropdown-treeview-control {
    left: 7px;
}

.product-menu-categories .product-menu-categories-hidden .menu-dropdown-treeview .menu-dropdown-item .menu-dropdown-link-wrap .menu-dropdown-link {
    padding: 0.4375rem 1.0625rem 0.4375rem 1.0625rem !important;
}

.menu-dropdown-classic .menu-dropdown-list .menu-dropdown-item .menu-dropdown-sub {
    left: 101% !important;
}

.menu-dropdown-accordion .menu-dropdown-item .menu-dropdown-sub .menu-dropdown-sub-inner {
    display: block !important;
}

.menu-dropdown-accordion .menu-dropdown-item .menu-dropdown-sub .menu-dropdown-sub-block {
    padding: 0 0 0 50px !important;
}


.product-menu-categories .menu-dropdown-list .menu-dropdown-link-wrap {
    padding-right: 0;
}

.product-menu-categories_prod_list {
    border: 2px solid #ececec;
    border-radius: 6px;
    margin-bottom: 30px;
}

.product-menu-categories_prod_list .menu-dropdown-list {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
}

.product-menu-categories .show-all-cat-dropdown {
    padding: 1em 2em;
    border-bottom: 1px solid #ececec;
    display: block;
    cursor: pointer;
    font-size: 14px;
}

.product-menu-categories .show-all-cat-dropdown .child-indicator {
    padding-left: 11px;
}

.product-menu-categories .show-all-cat-dropdown .child-indicator .fa-angle-right {
    font-size: 1.2em;
    color: #333e48;
    font-weight: 700;
    vertical-align: middle;
}

.product-menu-categories .show-all-cat-dropdown .child-indicator .fa-angle-up {
    font-size: 1.2em;
    color: #333e48;
    font-weight: 700;
    vertical-align: middle;
}

.product-menu-categories .menu-dropdown-list {
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 0px 12px 12px !important;
    margin-bottom: 0px !important;
}

.product-menu-categories-count {
    display: none;
}

.product-menu-categories .product-menu-categories-count {
    display: inline-block;
    color: #acacac;
    font-family: "OpenSansRegular";
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.product-menu-categories .menu-dropdown-list .menu-dropdown-item .menu-dropdown-link {
    padding: 0.4375rem 1.0625rem 0.4375rem 2.0625rem !important;
    color: black !important;
}

.product-menu-categories .menu-dropdown-sub-inner {
    display: none;
}

.product-menu-categories .menu-dropdown-item .menu-dropdown-link-wrap::after {
    display: none;
}

.product-menu-categories .menu-dropdown-item .menu-dropdown-link-wrap:hover {
    background-color: #fff;
}

.product-menu-categories .menu-dropdown-item .menu-dropdown-link:hover .product-menu-categories-count {
    color: black;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.product-menu-categories .menu-dropdown-list .product-menu-categories-current a {
    color: black;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.product-menu-categories .menu-dropdown-list .product-menu-categories-current:hover .product-menu-categories-count {
    color: black;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.categoryidbyproducthidden {
    display: none;
}

.categoryidbymenuhidden {
    display: none;
}

/*�������� ������*/

/*�������*/
.categoryidcataloghidden {
    display: none;
}

.catalog-filter .catalog-filter-header {
    font-size: 1.357em;
    line-height: .947em;
    margin-bottom: 20px;
    padding-bottom: 1.053em;
    position: relative;
    border-bottom: 1px solid #dadada;
}

.catalog-filter .catalog-filter-header:after {
    content: ' ';
    width: 4.684em;
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
}

.catalog-filter .catalog-filter-content {
    border: none;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-header {
    font-family: "OpenSansBold";
    color: black;
    font-size: 15px;
    padding: 0;
    border: none;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-header .catalog-filter-block-title {
    font-size: 15px;
    display: block;
    width: 92%;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content {
    border: none;
    padding: 20px 0;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row {
    padding: 6px 0px;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row .custom-input-text {
    font-size: 14px;
    color: black;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row .custom-input-text {
    font-size: 14px;
    color: black;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row label .custom-input-native:disabled~.custom-input-text,
.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row label .custom-input-native:disabled~.custom-input-text {
color: #94969b;
}

.catalog-filter .catalog-filter-content .catalog-filter-block {
    padding: 10px 0;
}

.catalog-filter .catalog-filter-content .catalog-filter-footer {
    border: none;
    padding: 0;
    margin-top: 20px;
}

.catalog-filter .catalog-filter-content .catalog-filter-footer .btn-submit {
    color: #fff;
    padding: 13px 15px;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
    cursor: pointer;
}

.catalog-filter .catalog-filter-content .catalog-filter-footer .btn-submit:hover {
    color: #fff;
    background-color: black;
}

.catalog-filter .catalog-filter-content .catalog-filter-footer .btn-action {
    color: #fff;
    border-color: #efecec;
    padding: 13px 20px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
    background-color: black;
    margin-right: 10px;
    cursor: pointer;
}

.catalog-filter .catalog-filter-content .catalog-filter-footer .btn-action:hover {
    color: #fff;
    background: #3a3737;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row .custom-input-checkbox {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #dfdfdf;
    border: 1.5px #dfdfdf solid;
    background: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

#modalBuilder .modal-content .builder-structure .builder-checkbox {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #dfdfdf;
    border: 1.5px #dfdfdf solid;
    background: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

#modalBuilder .builder-structure .form-field-name {
    display: none;
}

#modalBuilder .builder-structure .row .col-xs-3 {
    display: none;
}

#modalBuilder .builder-structure .builder-logo-generator {
    padding-left: 0;
}

#modalBuilder .modal-footer .btn-buy {
    color: #fff;
    padding: .7em 1.3em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 13px;
}

#modalBuilder .modal-footer .btn-action {
    color: #fff;
    padding: .7em 1.3em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 13px;
}

.catalog-filter .catalog-filter-content .catalog-filter-block-content .catalog-filter-row .custom-input-checkbox:hover {
    background: #dfdfdf;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.custom-input-native:checked ~ .custom-input-checkbox:after {
    background-image: none;
    top: -1px;
    width: 15px;
    height: 15px;
    left: -2px;
    border-radius: 3px;
}

.catalog-filter .catalog-filter-block-content .ngrs-range-slider .ngrs-runner {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.catalog-filter .catalog-filter-block-content .ngrs-range-slider .ngrs-runner .ngrs-handle {
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 16px;
    width: 16px;
    cursor: ew-resize;
}

.catalog-filter .catalog-filter-block-content .ngrs-range-slider .ngrs-runner .ngrs-handle:hover {
    -webkit-box-shadow: 0 0 0 0.236em rgba(0,0,0,.1);
    box-shadow: 0 0 0 0.236em rgba(0,0,0,.1);
}

.catalog-filter .catalog-filter-block-content .ngrs-range-slider .ngrs-runner .ngrs-join {
    background-color: #dcdcdc;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    height: 6px;
    top: -1.5px;
}

.catalog-title-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
}

.products-view-catalog-header {
    background-color: #f5f5f5;
    padding: .714em 1.429em;
    border-radius: 9px;
    margin-bottom: 20px;
}

.catalog-title-row .catalog-title {
    margin-bottom: 0;
}

.catalog-title-row .products-view-sort-result {
    margin-bottom: 0;
}

.catalog-page-products .products-view-tile, .catalog-page-products .products-view-list, .catalog-page-products .products-view-table {
    margin-left: 0;
    margin-right: 0;
}


/*����� ������� �� �������?*/
.products-view-block-hidden {
    display: none;
}

.catalog-page-products .products-view-list .products-view-labels {
    z-index: 999;
}

.catalog-page-products .products-view-list .products-view-block-hidden {
    display: block !important;
    width: 100%;
}

.catalog-page-products .products-view-list .products-view-category,
.catalog-page-products .products-view-list .products-view-name,
.catalog-page-products .products-view-list .products-view-pictures,
.catalog-page-products .products-view-list .products-view-info,
.catalog-page-products .products-view-list .products-view-price-block,
.catalog-page-products .products-view-list .hover-area {
    display: none !important;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-category,
.catalog-page-products .products-view-list .products-view-block-hidden .products-view-name,
.catalog-page-products .products-view-list .products-view-block-hidden .products-view-pictures,
.catalog-page-products .products-view-list .products-view-block-hidden .products-view-info,
.catalog-page-products .products-view-list .products-view-block-hidden .products-view-price-block,
.catalog-page-products .products-view-list .products-view-block-hidden .hover-area {
    display: block !important;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr-right .products-view-price-block {
    display: flex !important;
    align-items: center;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-pictures {
    position: relative;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr .products-view-description {
    color: #7d7d7d;
    font-size: 13px;
    line-height: 1.75em;
    height: 69px;
    overflow: hidden;
}

/*.catalog-page-products .products-view-list .products-view-item:hover {
    -webkit-box-shadow: 0 0 9px 0 rgb(0 0 0 / 25%);
    box-shadow: 0 0 9px 0 rgb(0 0 0 / 25%);
}*/

/*.catalog-page-products .products-view-list .products-view-item {
    padding: 0;
}*/

.catalog-page-products .products-view-list .products-view-block-hidden .hover-area {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    visibility: visible;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr .products-view-block-hidden-descr-left {
    width: 75%;
    padding-right: 10px;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr .products-view-block-hidden-descr-right {
    width: 300px;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr .products-view-price-block .products-view-price {
    text-align: right;
    margin-top: 16px;
}

.catalog-page-products .products-view-list .products-view-block-hidden .products-view-block-hidden-descr .hover-area {
    border-top: none;
    margin-top: 20px;
}

/*��� tile*/

.catalog-page-products .products-view-table .products-view-block-hidden {
    display: block !important;
    width: 100%
}

.catalog-page-products .products-view-table .products-view-category,
.catalog-page-products .products-view-table .products-view-name,
.catalog-page-products .products-view-table .products-view-pictures,
.catalog-page-products .products-view-table .products-view-info,
.catalog-page-products .products-view-table .products-view-price-block,
.catalog-page-products .products-view-table .hover-area {
    display: none !important;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-category,
.catalog-page-products .products-view-table .products-view-block-hidden .products-view-name,
.catalog-page-products .products-view-table .products-view-block-hidden .products-view-pictures,
.catalog-page-products .products-view-table .products-view-block-hidden .products-view-info,
.catalog-page-products .products-view-table .products-view-block-hidden .hover-area {
    display: block !important;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-price-block {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;

    .products-view-price {
        padding-top: 0;
    }
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-pictures {
    position: relative;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .products-view-description {
    color: #7d7d7d;
    font-size: 13px;
    line-height: 1.75em;
    height: 69px;
    overflow: hidden;
}

.catalog-page-products .products-view-table .products-view-item:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.catalog-page-products .products-view-table .products-view-item {
    padding: 0;

    .products-view-labels {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 1;
    }
}

.catalog-page-products .products-view-table .products-view-block-hidden .hover-area {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    visibility: visible;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .products-view-block-hidden-descr-left {
    width: 70%;
    padding-right: 10px;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .products-view-block-hidden-descr-right {
    width: 230px;
}

/*.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .products-view-price-block .products-view-price {
    text-align: left;
    margin-bottom: 16px;
    display: block;
}*/

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .products-view-price-block .products-view-buttons-cell .products-view-buttons {
    margin-top: 0;
    a {
        margin: 0 auto;
    }
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .hover-area {
    border-top: none;
    margin-top: 16px;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden-descr .products-view-price .price-old {
    text-align: right;
    margin-bottom: 5px;
}

.catalog-page-products .products-view-table .products-view-block-hidden .products-view-block-hidden_row {
    align-items: center;
}

.catalog-page-products .products-view-block-hidden .hover-area {
    height: 22px;
}

.catalog-page-products .products-view-table .products-view-block-hidden .row .col-xs-4 {
    -ms-flex-preferred-size: 20.33333% !important;
    flex-basis: 20.33333% !important;
    max-width: 20.33333% !important;
}

.catalog-page-products .products-view-table .products-view-block-hidden .row .col-xs-8 {
    -ms-flex-preferred-size: 79% !important;
    flex-basis: 79% !important;
    max-width: 79% !important;
}

.catalog-page-products .products-view-table .products-view-block-hidden .row .col-xs-4 .products-view-pictures {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
    height: auto !important;
}


/*tile*/
/*����� ������� �� �������?*/


.catalog-page-products .products-view-tile .products-view-block:nth-child(3n + 1) .products-view-info::after {
    content: '';
    border-right: 1px solid #eaeaea;
    display: block;
    position: absolute;
    top: 49%;
    right: 0;
    height: 76%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.catalog-page-products .products-view-tile .products-view-block:nth-child(3n) .products-view-info::before {
    content: '';
    border-right: 1px solid #eaeaea;
    display: block;
    position: absolute;
    top: 49%;
    left: 0;
    height: 76%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.catalog-page-products .products-view-table .products-view-item .products-view-block-hidden .products-view-buttons .btn-product-view-preorder {
    display: block;
    text-align: center;
}

.catalog-page-products .products-view-table .products-view-item .products-view-block-hidden .products-view-block-hidden-descr .hover-area .hover-area-compare:before,
.catalog-page-products .products-view-table .products-view-item .products-view-block-hidden .products-view-block-hidden-descr .hover-area .hover-area-wishlist:before {
    display: none;
}

.catalog-page-products .products-view-list .products-view-item .products-view-block-hidden .products-view-block-hidden-descr .hover-area {
    padding-top: 10px;
}



.site-body-main .product-categories-thin .product-categories-item-thin .products-view-block .products-view-price-block {
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
}

.site-body-main .product-categories-thin .product-categories-item-thin .products-view-block .hover-area {
    display: none;
}
/*�������*/
/*����� ��������*/
.site-footer-wrap .site-footer .footer_products {
    margin-top: 15px;
    margin-bottom: 50px;
}

.footer_products .footer_products_title {
    font-size: 1.429em;
    padding: 15px 0;
    border-bottom: 1px solid #dadada;
    margin-bottom: 40px;
    position: relative;
}

.footer_products .footer_products_title::after {
    content: ' ';
    width: 124px;
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
}

.footer_products .footer_products_list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.footer_products .footer_products_list li {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-bottom: 2.143em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 75px;
}

.footer_products .footer_products_list_block_img {
    min-width: 75px;
    margin-right: 20px;
}

.footer_products .footer_products_list_block_descr {
    /*min-height: 75px;*/
}

.footer_products .footer_products_list_block_descr .products-view-name {
    height: 34px;
    overflow: hidden;
    line-height: 16px;
    margin-bottom: 0;
}

.footer_products .footer_products_list_block_descr .products-view-name .products-view-name-link {
    color: black !important;
    font-family: "OpenSansBold";
    font-size: 14px;
}

.footer_products .footer_products_list_block_descr .products-view-price-block {
    margin-top: 15px;
}

.footer_products .footer_products_list_block_descr .products-view-price-block .products-view-price {
    font-size: 14px;
}

.footer_products .footer_products_list_block_descr .products-view-price-block .products-view-price .price-discount {
    display: none;
}

.footer_products .footer_products_list_block_descr .products-view-price-block .products-view-price .price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer_products .footer_products_list_block_descr .products-view-price-block .products-view-price .price .price-old {
    font-size: 12px;
    margin-left: 8px;
}

/*����� ��������*/

/*�������� ������*/
.product-card-row {
    margin-top: 50px;
}

.details-block-product .details-title-row .details-title {
    margin: 0;
}

.product-rating-reviews {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-rating-reviews .details-rating {
    margin-right: 20px;
}

.single-product-title-divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.product-card-exuding {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.product-card-exuding .block-exuding-row {
    border-bottom: 0;
    padding: 0;
    margin-right: 20px;
}

.product-card-exuding .block-exuding-row .compare-control {
    color: #949494;
    font-size: 13px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    cursor: pointer;
}

.product-card-exuding .block-exuding-row .custom-input-native {
    display: none;
}

.product-card-exuding .block-exuding-row .compare-control:hover {
    color: #000000;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.product-card-exuding .block-exuding-row .compare-control .ec-compare:before {
    font-size: 16px;
}

.product-card-exuding .block-exuding-row .compare-control .ec-compare {
    margin-right: 3px;
}

.product-card-exuding .block-exuding-row .compare-control .ec-compare:before:hover {
    cursor: pointer;
}

.product-card-exuding .block-exuding-row .compare-control .compare-checkbox:checked + .ec-compare {
    top: 0px;
}

.product-card-exuding .block-exuding-row .compare-control .compare-checkbox:checked ~ .custom-input-text .compare-text-added {
    top: 0px;
}

.product-card-exuding .block-exuding-row .wishlist-control {
    color: #949494;
    font-size: 13px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    cursor: pointer;
}

.product-card-exuding .block-exuding-row .wishlist-control:hover {
    color: #000000;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.product-card-exuding .block-exuding-row .wishlist-control .ec-favorites {
    margin-right: 3px;
}

.product-card-exuding .block-exuding-row .wishlist-control .ec-favorites:hover {
    cursor: pointer;
}

.product-card-exuding .block-exuding-row .wishlist-control .ec-favorites:before {
    font-size: 14px;
}

.product-card-available {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-card-available .details-brand {
    margin-bottom: 0;
    width: 50px;
    margin-right: 11px;
}

.product-card-available .details-brand .details-brand-link {
    display: block;
    max-width: 50px;
    position: relative;
    top: 1px;
}

.product-card-available .product-card-available-name {
    color: #8f8e94;
    font-size: 14px;
    margin-right: 6px;
}

.product-card-available .details-availability .availability {
    background-color: white;
    font-family: "OpenSansBold";
    font-size: 14px;
    padding: 0;
    padding-bottom: 1px;
}

.product-card-available .details-availability .available {
    color: #5cb85c;
}

.product-card-available .details-availability .not-available {
    color: #d9534f;
}

.product-card-properties {
    color: #7d7d7d;
    li {
        margin-top: 0;
    }
}

/*.product-card-exuding-aside {
    margin-bottom: 20px;
}*/

.product-card-properties .product-card-properties-li .products-view-meta {
    font-size: 12px;
}

.product-card-properties .product-card-properties-li .details-brand-name a {
    font-size: 12px;
    color: #7d7d7d;
}

.product-card-properties .product-card-properties-li .details-param-name {
    font-size: 12px;
}

.product-card-properties .product-card-properties-li .details-param-value {
    font-size: 12px;
}

.product-card-properties .product-card-properties-li .details-param-value .details-param-value-weight {
    font-size: 12px;
}

.details-block-product .details-payment-price .details-payment-price-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-top: 1rem;
}

.details-payment-price-no-reg {
    font-size: 16px;
    margin-top: 25px;
    color: #7d7d7d;
}

.details-block-product .details-payment-price .details-payment-price-price .price-old {
    font-size: 21px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    text-decoration: line-through;
    color: #848484;
    position: relative;
    top: -4px;
}

.details-block-product .details-payment-price .details-payment-price-price .price-new {
    font-size: 35px;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    margin-right: 15px;
}

.details-block-product .details-payment-price .details-payment-price-price .price-current {
    font-size: 35px;
}

.details-block-product .details-payment-price .details-payment-price-price .price-discount {
    display: none;
}

.details-block-product .details-payment-price .bonus-string-sum {
    color: #7d7d7d;
    margin-top: 8px;
}

.product-card-amount-buy .details-amount .details-param-value .input-small {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 48px;
    text-align: center;
}

.product-card-btn-buy {
    color: #fff;
    padding: 1.036em 3.434em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
}

.product-card-btn-buy:hover {
    color: #fff;
    background-color: black;
}

.product-card-btn-buy:before {
    display: inline-block;
    font: normal normal normal 14px/1 font-electro;
    font-size: 1.357em;
    vertical-align: middle;
    content: "\61";
    margin-right: .5em;
}

.product-card-btn-order {
    color: #fff;
    padding: 1.036em 3.434em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
}

.product-card-btn-order:hover {
    color: #fff;
    background-color: black;
}

.product-card-amount-buy-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-card-amount-buy .details-amount {
    margin-right: 10px;
}

.product-card-btn-oneclick {
    margin-bottom: 23px;
    margin-top: 10px;
}

.product-card-btn-oneclick a {
    color: #545353;
    font-size: 14px;
}

.product-card-btn-oneclick a:hover {
    color: black;
    font-size: 14px;
}

.details-block-product .gallery-picture-labels {
    left: 0;
    right: initial;
}

.details-block-product .gallery-picture-labels .products-view-label {
    text-align: left;
    font-weight: normal;
}

.details-block-product .tabs .tabs-content {
    border: 1px solid #ddd;
    border-radius: 1em;
    padding: 30px;
    display: table;
    font-size: .875rem;
    width: 100%;
}

.details-block-product .tabs .tabs-content .tab-content .review-inputs-row .form-field input {
    border-radius: 25px;
    padding: 13px 17px;
    font-size: 13px;
    color: #818181;
}

.details-block-product .tabs .tabs-content .tab-content .review-form .form-field textarea {
    border-radius: 25px;
    padding: 13px 17px;
    font-size: 13px;
    color: #818181;
}

.details-block-product .tabs .tabs-content .tab-content .review-form .review-form-button input {
    color: #fff;
    padding: 1.036em 3.434em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
}

.details-block-product .tabs .tabs-content .tab-content .review-form .review-form-button input:hover {
    color: #fff;
    background-color: black !important;
}

.details-block-product .tabs .tabs-headers {
    text-align: center;
    overflow: visible;
}

.details-block-product .tabs .tabs-headers .tabs-header-active {
    font-family: "OpenSansBold";
    position: relative;
}

.details-block-product .tabs .tabs-headers .tabs-header-item .tabs-header-item-link {
    color: #333e48;
    font-size: 20px;
    padding-bottom: 17px;
    display: inline-block;
}

.details-block-product .tabs .tabs-headers .tabs-header-item {
    display: inline-block;
    margin: 0 20px;
}

.details-block-product .tabs .tabs-headers .tabs-header-active .tabs-header-item-link:before {
    content: ' ';
    height: 4px;
    width: 10px;
    display: block;
    position: absolute;
    bottom: -6px;
    left: 50%;
    border-radius: 0 0 10px 10px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.details-block-product .tabs .tabs-headers .tabs-header-active .tabs-header-item-link:after {
    content: ' ';
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -2px;
    width: 100%;
}
/*.details-block-product .details-row-related-block .carousel-inner {
    width: 100.7% !important;
}

.details-block-product .details-row-related-block .carousel-inner .product-view-carousel-list {
    padding: 3px 3px 3px 3px;
}*/
/*.details-block-product .details-row-related-block .carousel-inner {
    width: 100.7% !important;
}
.details-block-product .details-row-related-block .carousel-inner .product-view-carousel-list {
    padding: 3px 3px 3px 3px;
}
.details-block-product .details-row-alternative-block .carousel-inner {
    width: 100.7% !important;
}

.details-block-product .details-row-alternative-block .carousel-inner .product-view-carousel-list {
    padding: 3px 3px 3px 3px;
}
.details-block-product .details-row-alternative-block .carousel-nav {
    right: 0;
    top: -42px;
}
.details-block-product .details-row-related-block .carousel-nav {
    right: 0;
    top: -42px;
}*/
/*.details-block-product .details-row-related-block .carousel-inner {
    width: initial !important;
}*/
/*.details-block-product .details-row-related-block .carousel-inner .product-view-carousel-list {
    padding: 3px 3px 3px 3px;
}*/
.details-block-product .product-card-edit {
    margin-bottom: 7px;
}

.details-block-product .product-card-info-block {
    padding-left: 35px;
}

.details-block-product .product-card-info-block .details-payment-price-price .price-unknown {
    margin-bottom: 15px;
    font-size: 16px;
}

.details-block-product .product-card-info-block .details-custom-options .custom-options-name {
    color: #7d7d7d;
    font-size: 14px;
}

.details-block-product .details-tabs {
    margin-top: 55px;
}

/*.details-block-product .details-payment-price {
    margin-top: 35px;
}*/

.product-catd-dostavka {
    margin-bottom: 10px;
    margin-top: 35px;
}

.product-card-namecategory {
    font-size: 12px;
    font-family: "OpenSansLight";
}

.product-card-namecategory a {
    color: #878787;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.product-card-namecategory a:hover {
    color: black;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.details-row-related-block {
    margin-top: 30px;
}

.details-row-related-block .block-head {
    border-bottom: 1px solid #dadada;
    margin-bottom: 25px;
}

.details-row-related-block .block-head .h2 {
    font-size: 1.786em;
    line-height: 1.6em;
    display: inline-block;
    padding-bottom: .4em;
    position: relative;
    margin-bottom: 0;
}

.details-row-related-block .block-head .h2:after {
    content: ' ';
    border-bottom: 2px solid transparent;
    display: block;
    position: absolute;
    bottom: -1px;
    width: 100%;
}

.details-row-related-block .block-content .products-view-tile {
    margin-left: 0;
    margin-right: 0;
}

.product-card-row .product-card-col-xs-3 {
    padding-right: 15px;
}

.product-card-row .details-block-product {
    padding-right: 15px;
}

/*�������� ������*/

.menu-dropdown-list .menu-dropdown-sub-inner .wpb_single_image .wpb_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.menu-dropdown-list .menu-dropdown-item .menu-dropdown-sub-columns-item .menu-dropdown-sub-block {
    z-index: 9999;
}

.menu-block .menu-dropdown .menu-dropdown-list .menu-dropdown-item .menu-dropdown-sub .menu-dropdown-sub-inner .menu-dropdown-sub-columns-item .menu-dropdown-sub-category-name {
    font-size: 15px;
    font-family: "OpenSansBold";
}

.menu-dropdown-sub-inner_padding {
    padding: 28px 45px;
}

.bg-yamm-content {
    position: relative;
    right: 0;
    bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: -50px;
}

.vc_column_container {
    padding-left: 0;
    width: 100%;
    padding-right: 0;
}

.vc_column_container > .vc_column-inner {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.wpb_single_image .vc_figure {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    max-width: 100%;
}

.wpb_single_image .vc_single_image-wrapper {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
}

.wpb_single_image img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}


.pushy-right-container {
    padding: 40px 36px 40px 40px;
}

.pushy-right-container_header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 15px;
}

.pushy-right-container_header_name {
    font-size: 24px;
    font-family: "OpenSansBold";
}


.cart-mini-result-block-custom {
    bottom: 0;
    position: absolute;
    right: 0;
    margin: 40px 40px 60px 40px;
    left: 0;
    border-top: 1px solid #c3c3c3;
    background: white;
}

.cart-mini-buttons-cart {
    display: block;
    border-radius: 0;
    font-family: "OpenSansBold";
    color: #fff;
    background: #242424;
    text-align: center;
    padding: 8px 10px;
    font-size: 18px;
    margin-bottom: 18px;
}

.cart-mini-buttons-cart:hover {
    text-decoration: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: #3c3b3b;
    color: #fff;
}

.cart-mini-buttons-checkout {
    display: block;
    border-radius: 0;
    font-family: "OpenSansBold";
    color: #fff;
    background: #242424;
    text-align: center;
    padding: 10px 10px;
    font-size: 18px;
}

.cart-mini-buttons-checkout:hover {
    text-decoration: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: #3c3b3b;
    color: #fff;
}

.cart-mini-result-row-totals {
    padding: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.cart-mini-result-row-totals .cart-mini-result-name {
    font-size: 20px;
    font-family: "OpenSansBold";
}

.cart-mini-result-name {
    font-family: "OpenSansBold";
}

.cart-mini-result-row-totals .cart-mini-result-value {
    font-size: 20px;
}

.cart-mini-list .cart-mini-info .cart-mini-name {
    font-family: "OpenSansBold";
    font-size: 16px;
}

.cart-mini-result-block-custom-mini-info {
    margin: 10px 0;
    font-size: 14px;
}

.cart-mini-list .cart-mini-info .cart-mini-count {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
}

.cart-mini-list .cart-mini-info .cart-mini-price {
    font-size: 14px;
}

.cart-mini-list .cart-mini-item-col-xs-3 {
    padding-right: 0;
    padding-left: 0;
}

.cart-mini .cart-mini-item-remove-item-block {
    position: relative;
}

.cart-mini .cart-mini-count .cart-mini-amount-control {
    margin-left: 3px;
}

.cart-mini .cart-mini-count .cart-mini-amount-control .spinbox-input-wrap {
    display: none;
}

.cart-mini-count .cart-mini-count-items {
    position: relative;
    top: 6px;
}

.cart-mini-item-remove-item {
    display: block;
    position: absolute;
    right: 6px;
    top: 12px;
}

.cart-mini-item .cart-mini-item-remove-item-block {
    position: relative;
}

.cart-mini-list .cart-mini-item-remove-item:before {
    content: '';
    background: url(../images/close.png);
    width: 9px;
    height: 9px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.cart-mini-info .cart-mini-count .cart-mini-amount-control .spinbox-more:before {
    content: '';
    background: url(../images/cart-mini-count-plus.png);
    width: 9px;
    height: 9px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    margin-bottom: 4px;
}

.cart-mini-info .cart-mini-count .cart-mini-amount-control .spinbox-less:before {
    content: '';
    background: url(../images/cart-mini-count-minus.png);
    width: 11px;
    height: 11px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    margin-bottom: 4px;
}


.pushy_close {
    position: absolute;
    right: 40px;
}

.pushy_close:before {
    content: '';
    background: url(../images/close.png);
    width: 15px;
    height: 15px;
    display: block;
    position: relative;
    right: 0;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.scroll-to-top.scroll-to-top-active {
    width: 45px;
    height: 45px;
    bottom: 55px;
    right: 20px;
    left: inherit;
    top: inherit;
    opacity: .5;
    color: #fff;
    border-radius: 3px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.scroll-to-top.scroll-to-top-active .to-top-icon {
    bottom: 0;
    color: #fff;
    font-size: 20px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 45px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.scroll-to-top.scroll-to-top-active .to-top-icon:before {
    margin-right: 0;
}

.scroll-to-top.scroll-to-top-active:hover {
    opacity: 1;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


.site-head {
    z-index: inherit;
    position: relative;
}

.site-body > .container, .site-body-inner {
    position: relative;
}

/* width */
.cart-mini-scrollbar::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.cart-mini-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.cart-mini-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.cart-mini-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.menu-dropdown-list .menu-dropdown-sub-inner_padding .menu-dropdown-sub-columns .menu-dropdown-sub-block-brand .menu-dropdown-sub-brand-title {
    font-family: "OpenSansBold";
}

.logo-footer {
    max-width: 300px;
}

.logo-container {
    margin-bottom: 0.625rem;
}

.recently-list__container {
    overflow: auto;
    max-height: 19.25rem !important;
}

.features-list .ec-transport:before {
    content: '' !important;
    background: url(../images/delivery-truck.png);
    width: 42px;
    height: 42px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.features-list .ec-customers:before {
    content: '' !important;
    background: url(../images/five-stars-outlines.png);
    width: 42px;
    height: 42px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.features-list .ec-returning:before {
    content: '' !important;
    background: url(../images/cycle-loading.png);
    width: 42px;
    height: 42px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.features-list .ec-payment:before {
    content: '' !important;
    background: url(../images/wallet.png);
    width: 42px;
    height: 42px;
    display: block;
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    background-repeat: no-repeat;
}

.category-picture img {
    max-width: 250px;
}

#modalCartPopup .cart-popup-content .cart-popup-image img {
    max-width: 180px;
}

#modalCartPopup .cart-popup-carousel .carousel-nav .carousel-nav-next:after {
    content: "\f105";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

#modalCartPopup .cart-popup-carousel .carousel-nav .carousel-nav-prev:after {
    content: "\f104";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

#modalCartPopup .cart-popup-count, #modalCartPopup .cart-popup-cost {
    font-weight: normal;
    font-family: "OpenSansBold";
}

#modalCartPopup .cart-popup-footer .cart-popup-cart-text:before {
    content: "\f171";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #171717;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

#modalCartPopup .cart-popup-content .cart-popup-spinbox .input-small {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 39px;
    text-align: center;
}

#modalCartPopup .cart-popup-footer .text-align-right .btn-action {
    font-size: 13px;
    border-radius: 110px;
    border-width: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-family: "OpenSansBold";
}

#modalCartPopup .cart-popup-footer .text-align-right .btn-buy {
    font-size: 13px;
    border-radius: 110px;
    border-width: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #333e48;
    background-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-family: "OpenSansBold";
}

#modalCartPopup .cart-popup-footer .text-align-right .btn-buy:hover {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.spinbox-less:before {
    content: "\f107";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.spinbox-more:before {
    content: "\f106";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.spinbox-less.cs-l-3:hover, .spinbox-more.cs-l-3:hover {
    color: #333e48;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.spinbox-more, .spinbox-less {
    padding: 0 0 0 4px !important;
}

.product-card-row .details-buy-in-time-block {
    width: 239px;
}

.product-card-row .details-buy-in-time-block .buy-in-time-block .buy-in-time-content .countdown .countdown-item-part {
    background: #e6e6e6;
    font-size: 22px;
    font-weight: normal;
    color: #000;
}

.product-card-row .details-buy-in-time-block .buy-in-time-block .buy-in-time-content .countdown .countdown-item-label {
    color: #000;
}

.static-banner-home-col-xs-3 {
    margin-bottom: 45px;
}

/*static block col-xs-9*/
.static-banner-home-col-xs-9 .da-block {
    margin-bottom: 5.286em;
}

.static-banner-home-col-xs-9 .da .media {
    background-color: #f3f2f7;
}

.static-banner-home-col-xs-9 .da .media-left {
    width: 60%;
}

.static-banner-home-col-xs-9 .da .media-middle img {
    vertical-align: middle;
}

.static-banner-home-col-xs-9 .media-left {
    padding-right: 10px;
}

.static-banner-home-col-xs-9 .media-body, .static-banner-home-col-xs-9 .media-left, .static-banner-home-col-xs-9 .media-right {
    display: table-cell;
    vertical-align: top;
}

.static-banner-home-col-xs-9 .media-middle {
    vertical-align: middle;
}

.static-banner-home-col-xs-9 .da .media-body {
    width: 40%;
}

/*.static-banner-home-col-xs-9 .da .media-body {
    padding: 3.286em 0 2.143em 0;
}*/

.static-banner-home-col-xs-9 .da .da-text {
    font-size: 1.571em;
    line-height: 1.278em;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.429em;
    font-family: "OpenSansLight";
}

.static-banner-home-col-xs-9 .da .da-text strong {
    font-family: "OpenSansBold";
}

.static-banner-home-col-xs-9 .da .da-action > a {
    padding: .7em 2em;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 13px;
    background: #fed700;
    font-family: "OpenSansBold";
    text-transform: uppercase;
    color: #333e48;
}

.static-banner-home-col-xs-9 .da .da-action > a .from {
    position: relative;
}

.static-banner-home-col-xs-9 .da .da-action > a .from, .static-banner-home-col-xs-9 .da .da-action > a .upto {
    text-transform: uppercase;
    font-weight: 300;
}

/*.static-banner-home-col-xs-9 .da .da-action > a::after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    content: "\f105";
    font-weight: 700;
    margin-left: 8px;
    padding-left: 1px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}*/

/*static block col-xs-9*/

#modalCartPopup .cart-popup-carousel .products-view-block .products-view-buttons .btn {
    font-size: 13px;
    border-radius: 110px;
    border-width: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #333e48;
    background-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    font-family: "OpenSansBold";
    padding-left: .625rem;
}

#modalCartPopup .cart-popup-carousel .products-view-block .products-view-buttons .btn:before {
    display: none;
}

#modalCartPopup .cart-popup-carousel .products-view-block .products-view-buttons .btn:hover {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

@-webkit-keyframes svg-loader {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes svg-loader {
    from {
        -ms-transform: rotate(0);
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.social-btns {
    margin-top: 25px;
}

.social-btns #share42 {
    display: flex;
    align-items: center;
}

#share42 .share42-item {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 13px 0px 0 !important;
}

#share42 .share42-item a {
    background: none !important;
    color: #7c7c7c;
    font-size: 27px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

#share42 .share42-item a:hover, #share42 .share42-item a:focus {
    text-decoration: none;
    color: #494949;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

#share42 .share42-item:nth-child(1) a:before {
    content: "\f189";
}

#share42 .share42-item:nth-child(2) a:before {
    content: "\f09a";
}

#share42 .share42-item:nth-child(3) a:before {
    content: "\f263";
}

#share42 .share42-item:nth-child(4) a:before {
    content: "\f099";
}

#share42 .share42-item:nth-child(5) a:before {
    content: "\f0d2";
}

/*.bodyNotScroll {
    overflow: hidden;
}*/

.cart_page {
    margin-top: 50px
}

.cart_page .cart-title {
    text-align: center;
}

.cart_page .cart-full-header .cart-full-header-item {
    font-family: "OpenSansBold";
    color: #747474;
}

.cart_page .cart-full-body .cart-full-amount .cart-full-amount-control input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    text-align: center;
    width: 100px;
    font-size: 15px;
}

.cart_page .cart-full-body .cart-full-amount .cart-full-amount-control .spinbox-input-wrap {
    padding-left: 26px;
}

.cart_page .cart-full-body .cart-full-price, .cart_page .cart-full-body .cart-full-cost, .cart_page .cart-full-body .cart-full-product .cart-full-name-link {
    color: black;
}

.cart_page .cart-full-body .cart-full-remove, .cart_page .cart-full-header .cart-full-remove {
    padding-left: 40px;
}

.cart_page .cart-full-body .cart-full-remove a:before {
    content: '';
    background: url(../images/close.png);
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
}

.cart_page .cart-full-header .cart-full-remove a:before {
    content: '';
    background: url(../images/close.png);
    position: relative;
    right: 0;
    background-size: 100%;
    top: 0;
    cursor: pointer;
    width: 11px;
    height: 11px;
    background-repeat: no-repeat;
}


.cart_page .cart-full-addition .cart-full-coupon .col-xs-8 {
    padding-right: 0;
}

.cart_page .cart-full-addition .cart-full-coupon .col-xs-8 .input-small {
    border-top-left-radius: 2.467em;
    border-bottom-left-radius: 2.467em;
    background: #fff;
    line-height: 1.34em;
    padding: 1.01em 2.134em;
    height: 47px;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cart_page .cart-full-addition .cart-full-coupon .checkout-gift-button {
    padding-left: 0;
}

.cart_page .cart-full-addition .cart-full-coupon .checkout-gift-button input {
    background-color: #333e48;
    border-color: #333e48;
    color: #fff;
    border-top-right-radius: 2.467em;
    border-bottom-right-radius: 2.467em;
    height: 47px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-family: "OpenSansBold";
    font-size: 14px;
}

.cart_page .cart-full-addition .cart-full-coupon .checkout-gift-button input:hover {
    background-color: #2a3035;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.cart_page .cart-full-buttons .btn-submit {
    color: #fff;
    padding: 1.036em 3.434em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
}

.cart_page .cart-full-buttons .btn-submit:hover {
    color: #fff;
    background-color: black !important;
}

.cart_page .cart-full-buttons .btn-action {
    color: #fff;
    padding: 1.036em 1.634em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
    font-family: "OpenSansBold";
    background-color: black;
    margin-right: 10px;
}

.cart_page .cart-full-buttons .btn-action:hover {
    color: #fff;
    background: #3a3737;
}

.cart_page .block .block-content .products-view-tile .products-view-block {
    padding: 3px;
}

/*.cart_page .block-content .products-view-tile .products-view-block:nth-child(3n + 1) .products-view-info::after {
    content: '';
    border-right: 1px solid #eaeaea;
    display: block;
    position: absolute;
    top: 49%;
    right: 0;
    height: 76%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}*/


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.mobile-container-fluid {
    margin-top: 15px;
}

.compare-title, .wishlist-title {
    margin-top: 1.25rem;
}

.checkout-block-content .mobile-block .form-field-name {
    font-size: 14px;
}

.checkout-block-content .mobile-block .form-field-input {
    font-size: 14px;
}

.checkout-block-content .mobile-block .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.preorder-form .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.preorder-form .form-field-input textarea {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    font-size: 14px;
}

.checkout-block-content .checkout-block-content-coupon-custom .col-xs-8 {
    padding-right: 0;
}

.checkout-block-content .checkout-block-content-coupon-custom .col-xs-8 input {
    border-top-left-radius: 2.467em;
    border-bottom-left-radius: 2.467em;
    background: #fff;
    line-height: 1.34em;
    padding: 1.01em 2.134em;
    height: 44px;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 14px;
}

.checkout-block-content .checkout-block-content-coupon-custom .col-xs-4 {
    padding-left: 0;
}


.checkout-block-content .checkout-block-content-coupon-custom .col-xs-4 input {
    background-color: #333e48;
    border-color: #333e48;
    color: #fff;
    border-top-right-radius: 2.467em;
    border-bottom-right-radius: 2.467em;
    height: 44px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-family: "OpenSansBold";
    font-size: 14px;
}

.checkout-block-content [name="checkoutNewCustomerForm"] .mobile-block .row .col-md-4 {
    padding-left: 0;
}


.custom-input-checkbox:hover {
    background: #dfdfdf;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.custom-input-checkbox {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #dfdfdf;
    border: 1.5px #dfdfdf solid;
    background: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    box-shadow: none !important;
}

.custom-input-native:checked ~ .custom-input-checkbox:after {
    background-image: none;
    top: -1px;
    width: 15px;
    height: 15px;
    left: -2px;
    border-radius: 3px;
}

.btn-submit-checkout-custom button {
    color: #fff;
    padding: 1.036em 3.434em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
}

.btn-submit-checkout-custom button:hover {
    color: #fff;
    background-color: black;
}

.icon_topbar_top_admin {
    margin-right: 8px;
    width: 14px;
    height: 14px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.icon_topbar_top_admin:before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    content: "\f201";
    position: relative;
    top: -1.2px;
}

.page-reg .form-field-name {
    font-size: 14px;
}

.page-reg .form-field-name {
    font-size: 14px;
}

.page-reg .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.page-reg .middle-xs .col-xs-offset-4 .group-reg {
    padding: 1em 2em;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
}

.page_login .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.page_login .page_login_login input {
    padding: .857em 2.214em;
}

.page_login .login-block-registration .btn-confirm {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.widget_electro_features .features-list .media i {
    color: #fed700;
}

.widget_electro_features .features-list .media span {
    color: #fed700;
}

.details-block-product .details-sizes .sizes-viewer-header {
    font-size: 13px;
}

.details-block-product .details-sizes .sizes-viewer-block .sizes-viewer-item .sizes-viewer-inner {
    color: #575b66;
}

.body-content .check-order {
    margin-bottom: 45px;
}

.body-content .check-order .block-alt-content .check-order-field input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 15px;
}

.body-content .check-order .block-alt-content .btn-action {
    color: #fff;
    padding: 1em 2em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
}

.body-content .block-certificate {
    margin-bottom: 45px;
    padding: 0;
}

#modalQuickView .modal-content .row .details-brand.fr {
    display: none;
}

#modalQuickView .modal-content .row .page-title-row {
    display: none;
}

#modalQuickView .modal-content .row .page-title-row.page-title-row-quick {
    display: block;
}

#modalQuickView .modal-content .row .page-title-row .details-title {
    font-size: 1.875rem;
    margin: 0;
}

#modalQuickView .row .col-xs-4 {
    width: 635px;
}

.compareproduct-container .compareproduct-products-wrapper .compareproduct-product-item-wrap .compareproduct-product-btns .btn {
    color: #fff;
    padding: .8em 1.8em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
}

.buy-one-ckick-very-top .modal-content .buy-one-click-buttons .btn {
    color: #fff;
    padding: 1em 3em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
}

.buy-one-ckick-very-top .modal-content .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.buy-one-ckick-very-top .modal-content .form-field-input textarea {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    font-size: 14px;
}

.buy-one-ckick-very-top .modal-content .form-field-name {
    font-size: 14px;
}

.modal-product-photo {
    padding: 0 65px;
}

.modal-product-photo .adv-modal-close {
    width: 45px !important;
    height: 35px !important;
}

.adv-modal-close {
    margin-left: 0 !important;
    margin-top: 1px;
    width: 25px !important;
}

.modal-product-photo {
    padding: 0 65px;
}

.modal-product-photo .adv-modal-close {
    width: 45px !important;
    height: 35px !important;
}

.adv-modal-close:after {
    content: '';
    background: url(../images/close-modal.png);
    width: 18px;
    height: 18px;
    display: block;
    position: relative;
    right: 0;
    top: 0px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100%;
}

.builder-dialog .adv-modal-close:after {
    content: '';
    background: url(../images/close-modal-builder.png);
    width: 18px;
    height: 18px;
    display: block;
    position: relative;
    right: 0;
    top: 0px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 100%;
}

#modalFindCheaper .modal-content .form-field-name {
    font-size: 14px;
}

#modalFindCheaper .modal-content .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

#modalFindCheaper .modal-footer .btn-action {
    color: #fff;
    padding: 1em 2em;
    border-color: #efecec;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
}

.toolbar-bottom {
    background-color: #000000 !important;
}

.site-footer-wrap .carousel-logo .carousel-nav .carousel-nav-next::after {
    content: "\f105";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.site-footer-wrap .carousel-logo .carousel-nav .carousel-nav-next:hover:after {
    color: #333e48 !important;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.site-footer-wrap .carousel-logo .carousel-nav .carousel-nav-prev::after {
    content: "\f104";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.site-footer-wrap .carousel-logo .carousel-nav .carousel-nav-prev:hover:after {
    color: #333e48 !important;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.pagenumberer .pagenumberer-next .icon-right-open-after:after {
    content: "\f105";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.pagenumberer .pagenumberer-next .icon-right-open-after:hover:after {
    color: #333e48 !important;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.pagenumberer .pagenumberer-prev .icon-left-open-after:after {
    content: "\f104";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #b4b4b4;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.pagenumberer .pagenumberer-prev .icon-left-open-after:hover:after {
    color: #333e48 !important;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.pagenumberer {
    display: flex;
    align-items: center;
    justify-content: center;
}

[data-oc-lazy-load="['modules/shippingpaymentpage/scripts/shippingpaymentpage.js']"] {
    margin-top: 30px;
}

[data-oc-lazy-load="['modules/shippingpaymentpage/scripts/shippingpaymentpage.js']"] [name="shippingPaementZone"] .shipping-payment-zone-button .btn {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
    font-size: 14px;
}

[data-oc-lazy-load="['modules/shippingpaymentpage/scripts/shippingpaymentpage.js']"] [name="shippingPaementZone"] .shipping-payment-zone-button .btn:hover,
[data-oc-lazy-load="['modules/shippingpaymentpage/scripts/shippingpaymentpage.js']"] .shipping-payment-list .shipping-payment-item .shipping-payment-field .shipping-payment-additional .btn-submit:hover {
    background-color: black;
}

[data-oc-lazy-load="['modules/shippingpaymentpage/scripts/shippingpaymentpage.js']"] .shipping-payment-list .shipping-payment-item .shipping-payment-field .shipping-payment-additional .btn-submit {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: 50px;
}

.tabs_my_accaunt .form-field-name {
    font-size: 14px;
}


.tabs_my_accaunt .form-field-input .input-small {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 13px;
}

.tabs_my_accaunt .form-field-input input,
.tabs_my_accaunt .address-list input,
.tabs_my_accaunt .bonus-form-labels .bonus-form-label-row .btn-middle,
.tabs_my_accaunt .order-history .btn-action {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    font-size: 13px;
}

.mobile-layout .mobile-layout__wrapper .mobile-layout__button {
    padding: .857em 1.214em;
    border-radius: 98px;
}

#zonePopover .adv-popover-content .text-align-center .btn-buy {
    border-radius: 50px;
    padding: 0.3125rem 12px 0.3125rem;
    transition: .3s;
}

.managers-email-dialog, .managers-call-dialog {
    min-width: 33.5rem !important;
}

.managers-call-dialog .form-field-name, .managers-email-dialog .form-field-name, #modalCallback .form-field-name {
    font-size: 14px;
}

.managers-call-dialog .form-field-input, .managers-email-dialog .form-field-input, #modalCallback .form-field-input {
    font-size: 14px;
}

.managers-call-dialog .form-field-input input, .managers-email-dialog .form-field-input input, #modalCallback .form-field-input input {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

.managers-call-dialog .form-field-input textarea, .managers-email-dialog .form-field-input textarea, #modalCallback .form-field-input textarea {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    font-size: 14px;
}

.managers-call-dialog .modal-footer input, .managers-email-dialog .modal-footer input, #modalCallback .modal-footer input {
    border-radius: 1.571em;
    font-size: 13px;
    padding: 0.75rem 1rem;
}

.menu_fast_toggle .menu-dropdown-item .menu-dropdown-sub {
    display: block;
    background: white;
    border-radius: 0px;
    border-top: none;
    box-shadow: rgba(0, 0, 0, 0.25) 3px 2px 9px -1px;
    top: 0;
}

.menu_fast_toggle .menu-dropdown-item .menu-dropdown-sub .menu-dropdown-sub-inner {
    border-top-style: none;
    box-shadow: none;
    position: relative;
    border: none !important;
    top: 0;
}

.product-categories-slim {
    margin: 0 -.625rem 10px !important;
}

.preorder-title {
    margin: 1.25rem 0 1.25rem 0;
}

[name="feedbackForm"] .form-field-name {
    font-size: 14px;
}


[name="feedbackForm"] .form-field-input .input-small {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    height: 41px;
    font-size: 14px;
}

[name="feedbackForm"] .form-field-input .textarea-middle {
    padding: .857em 1.214em;
    border-radius: 1.571em;
    font-size: 14px;
}

[name="feedbackForm"] .row input[type="submit"] {
    border-radius: 1.571em;
    font-size: 13px;
    padding: 0.8rem 1.2rem;
}

[name="shippingPaymentZone"] .shipping-payment-zone-button .btn-expander {
    border-radius: 1.571em;
}

.breads {
    display: flex;
    align-items: center;
}

.breads .breads-item-fa {
    flex-shrink: 0;
}

.breads .breads-item-last {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.products-view-label {
    margin-bottom: 3px;
}

.products-view-label-inner {
    border-radius: 0;
    font-weight: normal;
    text-transform: lowercase;
    font-size: 13px;
    padding: 1px 18px;
}

.products-view-label-best {
    background-color: #c6d86c;
}

.products-view-label-discount {
    background-color: #eb949d;
}

.products-view-label-new {
    background-color: #a0dbd9;
}

.products-view-label-sales {
    background-color: #75c8cc;
}

.products-view-label-recommend {
    background-color: #f3bbae;
}

.products-view-tile .products-view-labels {
    top: 5.625rem;
}

.category-description {
    font-size:14px;
}

.product-categories-thin .product-categories-item-thin .product-categories-thin-total {
    background-color: black;
    transition:.3s;
}

.product-categories-thin .product-categories-item-thin .product-categories-thin-total:hover {
    background-color: #565252;
    transition: .3s;
}

.details-briefdescription {
    margin-bottom: .9375rem;
}

#modalQuickView .details-payment-price .details-payment-price-price {
    margin: 16px 0;
}

#modalQuickView .details-payment-price .details-payment-price-price .price-old {
    font-size: 19px;
    color: #848484;
}

#modalQuickView .details-payment-price .details-payment-price-price .price-new {
    font-size: 27px;
}

#modalQuickView .details-payment-price .details-payment-price-price .price-current {
    font-size: 27px;
}

.site-footer .subscribe-block .form-field-input {
    text-align: center;
}

.details-payment-item-credit {
    margin-top: 20px;
}

@media (max-width: 1300px) {
    .details-block-product .tabs .tabs-content {
        width: initial;
    }
}

.social-widgets__item {
    margin-bottom: 17px;
    width: 31%;
    padding: 0 1%;
}
.details-payment {
    padding: 0;
    border-style: none;
    border-width: initial;
}

.tab-content.best-sellers.active, .tab-content.new-products.active, .tab-content.sales.active {
    display: block;
}

.home_prodlist_custom.wow.fadeIn .owl-nav {
    top: -87px;
}

.details-rating-wrap {
    display: flex;

    .rating {
        margin-top: 7px;
    }
}
/* Modules storereviews */
.store-reviews .breads {
    margin: 0;
}

/* Modules lastorder */
.details-rating .notify-wrap {
    margin-top: 15px !important;
    width: 100% !important;
}

/* Modules ordernow */
.details-rating .ONwrap {
    margin: 0 !important;
    padding: 0 !important;
}
.block-exuding .ONwrap {
    padding: 0 0 15px 0 !important;
}
.details-payment-price .ONwrap {
    padding: 0 !important;
}

.site-body {
    z-index: inherit;
}

.toolbar-bottom {
    z-index: none;
}

.owl-stage .owl-item.active:first-child .products-view-photos {
    left: 100% !important;
}

.product-categories-thin .product-categories-item-thin {
     margin-bottom: 0;
}

.product-rating-reviews .rating {
    margin-right: 1rem;
}

.autocompleter-sub {
    /*z-index: -1;*/
    opacity: .99;
}

.mobile-redirect-panel .device-panel__btn {
    width: 100%;
    background-color: black;
    color: white;
}
.cookies-policy {
    z-index: 110;
}

.owl-carousel-home-general {
    .products-view-block {
        flex-basis: initial !important;
        max-width: initial !important;
    }
}

.owl-carousel-productList {
    .products-view-block {
        -webkit-flex-basis: 100% !important;
        -ms-flex-preferred-size: 100% !important;
        flex-basis: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    .products-view-photo-wrap {
        height: auto !important;

        .products-view-photos-item {
            height: auto !important;
            width: auto !important;
        }
    }
}