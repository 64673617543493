body {
    .carousel-inner, .carousel-logo-inner {
        min-height: 80px;

        .carousel-logo-list {
            display: inline-block;
            position: relative;
            line-height: 0;
            font-size: 0;
            text-align: center;
            vertical-align: middle;
            width: 100%;
        }

        .carousel-logo-item {
            visibility: visible;
            width: 212px;
            max-width: 212px;
            flex-basis: 212px;
            display: inline-block;
            visibility: visible;
        }
    }

    .carousel-colors .carousel-inner {
        min-height: 0;
        }
    }
