.flatpickr-day.weekend {
    color: #eeeeee;
}
.flatpickr-day.weekend:hover {
    border-color: #eeeeee;
    background: #eeeeee;
    color: #fff;
}
.flatpickr-day.weekend.nextMonthDay:hover {
    border-color: #eeeeee;
    background: #eeeeee;
}
.flatpickr-day.selected.weekend {
    border-color: #cee3fd;
    background: #cee3fd;
}

.flatpickr-rContainer .flatpickr-days,
.flatpickr-rContainer .dayContainer {
    width: 100%;
    min-width: initial;
}
.flatpickr-custom{
    display: flex;
}
input.flatpickr-custom__input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.flatpickr-custom__toggle {
    background-color: #eee;
    border: 1px solid #e2e3e4;
    border-width: 1px 1px 1px 0;
    padding: 6px 12px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items:center;
    justify-content: center;
}