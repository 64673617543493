.menu-dropdown-root {
    color: #334141;
    border-radius: 0;
}

.menu-dropdown-item:hover .menu-dropdown-link-wrap {
    box-shadow: none;
}

.menu-dropdown-item:last-child .menu-dropdown-link-wrap {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.menu-dropdown-item:first-child .menu-dropdown-link-wrap {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.menu-dropdown-classic .menu-dropdown-sub-inner, .menu-dropdown-modern .menu-dropdown-sub-inner {
    border-top-width: 2px;
    border-top-style: solid;
    border-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    left: 0px;
    border-top: none;
    top: 2px;
    box-shadow: 3px 2px 9px -1px rgba(0, 0, 0, 0.25);
}

.menu-dropdown-root:hover {
    color: black;
}

.menu-dropdown:hover .menu-dropdown-list, .menu-dropdown.active .menu-dropdown-list {
    box-shadow: 0px 3px 9px -1px rgba(0, 0, 0, 0.25);
}

.menu-dropdown-treeview .menu-dropdown-sub-block {
    padding: 0 0 0 1.4375rem;
}

.menu-dropdown-sub-category, .menu-dropdown-sub-category-name, .menu-dropdown-sub-brand-title {
    margin-bottom: 0.5375rem;
    width: 100%;
}