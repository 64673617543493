.order-history-products-table {
    font-size: 18px;
    border-width: 0 0 1px;
    border-style: solid;

    &__row--bold {
        font-weight: bold;
    }

    &__head,
    &__body,
    &__footer {
        border-width: 1px 0 0 0;
        border-style: solid;
    }

    &__cell-content {
        padding: 10px 0;
    }


    @media(min-width:48em) {
        &__cell-content--right-adaptive {
            text-align: right;
        }

        &__head-cell,
        &__body-cell,
        &__footer-cell {
            &:first-child {
                .order-history-products-table__cell-content {
                    padding-left: 0;
                }
            }

            &:last-child {
                .order-history-products-table__cell-content {
                    padding-right: 0;
                }
            }
        }

        &__head,
        &__body .order-history-products-table__row:first-child,
        &__footer .order-history-products-table__row:first-child {
            .order-history-products-table__cell-content {
                padding-top: 20px;
            }
        }

        &__head,
        &__body .order-history-products-table__row:last-child,
        &__footer .order-history-products-table__row:last-child {
            .order-history-products-table__cell-content {
                padding-bottom: 20px;
            }
        }
    }
}
