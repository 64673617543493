@use "sass:math";

@import "../../../../styles/_settings.scss", "../../../../styles/functions/_util.scss";

.shipping-item {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
}

.shipping-item-col {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.shipping-item-icon {
    flex-shrink: 0;
}

.shipping-item-icon {
    & > img {
        cursor: pointer;
        vertical-align: middle;
        object-fit: contain;
    }
}

.shipping-item-info {
    padding: 0 0 0 $padding-base;
}

.shipping-item-title,
.shipping-item-description {
    display: block;
    line-height: 1.2;
    cursor: pointer;

    &--flex {
        display: flex;
    }
}

.shipping-item-description {
    font-size: 0.75rem;
    padding-top: rem-calc(5px);
    padding-bottom: rem-calc(5px);
}

.shipping-item-title {
    font-size: $font-size-medium;
    line-height: 1;
}

.shipping-item-more {
    font-size: $font-size-medium;
}

.shipping-variants {
    padding: 0;
    margin: 0;
    list-style: none;
    display: table;
    width: 100%;
}

.shipping-variants-row {
    display: table-row;
}

.shipping-variants-cell {
    display: table-cell;
    vertical-align: middle;
}

.shipping-variants-name {
    padding: rem-calc(5px) rem-calc(5px) rem-calc(5px) 0;
}

.shipping-variants-value {
    padding: rem-calc(5px) 0 rem-calc(5px) rem-calc(5px);
    width: rem-calc(65px);
}

.shipping-item-additional-description {
    display: none;
    font-size: 12px;
    margin-top: 5px;
}

.shipping-item--selected {
    .shipping-item-additional-description {
        display: block;
    }
}

.shipping-spinbox {
    width: rem-calc(70px);
    vertical-align: middle;
    display: inline-block;
    margin-left: rem-calc(5px);
}

.shipping-item-additional .shipping-control-select {
    width: 300px;
}

.is-mobile .shipping-item-additional .shipping-control-select {
    width: 100%;
}

.shipping-extend-error {
    display: none;
}

.ng-invalid.ng-submitted {
    .shipping-extend-error {
        display: block;
    }
}

.shipping-extend-input-hidden {
    opacity: 0;
    height: 0;
    width: 0;
    border: 0;
}

.shipping-progress {
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, .5);
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 10;
    }
}

.shipping-progress-after:after {
    content: "\e80b";
    left: 50%;
    top: 50%;
    font-size: rem-calc(35px);
    margin: math.div(rem-calc(-35px), 2) 0 0 math.div(rem-calc(-35px), 2);
    position: absolute;
    transform: translateY(-50%);
    animation: spinShipping 2s infinite linear;
    display: inline-block;
    text-shadow: none;
    font-family: "advantshopfonts";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    line-height: 1;
    text-align: center;
    opacity: 1;
    font-variant: normal;
    text-transform: none;
}

.shipping-empty {
    padding: rem-calc(40px) 0;
}

.shipping-template-newshipping {
    margin-top: 5px;
}

.shipping-template-row {
    margin-bottom: 15px;
}

.shipping-template-name {
    margin-bottom: 5px;
    font-size: 90%;
}

.shipping-item-input-price {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

.grastin-widget-dialog {
    min-width: 770px;
    min-height: 500px;
    width: 80%;
}

@media (min-width: 49em) {
    .shipping-dialog {
        min-width: 70vw;
        min-height: 500px;
    }
}


@keyframes spinShipping {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(359deg);
    }
}
