.modal-product-photo .details-modal-preview {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    max-height: 100vw;
    border-radius: 0;
}

.modal-product-photo .details-modal-preview .details-modal-preview-block .gallery-picture .zoomer-inner {
    height: initial !important;
    pointer-events: none;
}

.modal-product-photo .details-modal-preview .details-modal-preview-block {
    padding: 0;
}

.modal-product-photo .details-modal-preview .modal-header {
    display: none;
}

.modal-product-photo .details-modal-preview-block .details-modal-preview-cell-photo .gallery-picture {
    height: 43vw !important;
}

.modal-product-photo .details-modal-preview-block .details-modal-preview-cell-photo .gallery-picture .details-preview-block-link .gallery-picture-obj {
    max-height: 43vw !important;
}

.modal-product-photo .modal-background-product-right:hover:before {
    color: white;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.modal-product-photo .modal-background-product-left:before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #ccc;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    content: "\f177";
    background: rgba(30, 30, 30, 0.6);
    padding: 8px;
}

.modal-product-photo .modal-background-product-left:hover:before {
    color: white;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.modal-product-photo .details-modal-preview .adv-modal-close{
    left: initial;
    z-index: 1;
}

.modal-product-photo .modal-background-product-right:before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 21px;
    color: #ccc;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    content: "\f178";
    background: rgba(30, 30, 30, 0.6);
    padding: 8px;
}

.modal-product-photo .details-modal-preview .details-modal-preview-arrow {
    width: 45px;
}

.modal-product-photo .details-modal-preview .adv-modal-close:after {
    content: '';
    background: url(../../../../images/close-modal-builder.png);
    display: block;
    width: 21px;
    height: 22px;
    background-repeat: no-repeat;
    margin-top: 11px;
    margin-right: 11px;
    background-size: 20px;
}

.modal-product-photo .details-modal-preview .details-modal-preview-block .details-modal-carousel {
    display:none;
}

.modal-product-photo .details-modal-preview .gallery-picture .zoomer-window {
    display:none;
}