@import "../_settings.scss";
@import "../functions/_util.scss";

.properties {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
    overflow: inherit;
}

.properties-group-name,
.properties-item-name,
.properties-item-value,
.properties-item-title-name,
.properties-item-title-value {
    margin-left: $margin-value-base;
    margin-right: $margin-value-base;
}

.properties-group-name {
    font-size: $font-size-xmedium;
    margin-top: $margin-value-base;
    margin-bottom: $margin-value-base;
}

.properties-item-name, .properties-item-value {
    font-size: $font-size-normal;
    margin-top: $vertical-interval-middle;
    margin-bottom: $vertical-interval-middle;
}

.properties-item-title-name,
.properties-item-title-value {
    font-size: $font-size-normal;
    margin-top: $vertical-interval-middle;
}

.properties-item-hint {
}

.properties__state {
    position: absolute;
    display: none;

    &:checked {
        & + .properties .properties__item--state-hidden {
            display: block;
        }

        & ~ .properties__trigger {
            .properties__trigger--show {
                display: none;
            }

            .properties__trigger--hide {
                display: block;
            }
        }
    }
}

.properties__item--state-hidden {
    display: none;
}

.properties__trigger {
    margin-top: 20px;
    margin-left: 1.0625rem;
}
.properties__trigger--show {
    display: block;
}

.properties__trigger--hide {
    display: none;
}