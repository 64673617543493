.products-view-tile .products-view-name {
    text-align: left;
}

.products-view-tile .products-view-item {
    padding-left: 20px !important;
 }

.products-view-tile .products-view-block {
    padding: 0;
}

.products-view-tile .products-view-pictures {
    width: 100% !important;
    padding:0;
    margin: 0 auto;
}

/*.products-view-tile .products-view-name-default {
    height: 3rem;
}*/

.products-view-tile .products-view-price {
    height: 2.875rem;
    padding:0;
}

.products-view-tile .products-view-price-block {
    padding: 0;
    margin-top: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.products-view-tile .products-view-price-block:after {
    display:none;
}

/*.products-view-item:hover {
    box-shadow: 0 0 6px 0 rgba(1,1,1,.3);
}*/

.products-view-sort, .products-view-sort-result, .products-view-variants {
    margin-bottom: 0;
}

.products-view-tile .products-view-buttons {
    padding-bottom: 0; 
}

.products-view-tile .carousel-colors .carousel-inner {
    width: auto;
}

.products-view-tile {
    margin-top: 0.625rem;
}

/*.myaccount__wishlist .products-view-picture-link {
    max-width: 42%;
}
*/
.products-view-meta-item.details-reviews a {
    font-size: 14px;
    margin-left: 5px;
}

.products-view-tile .products-view-info {
    text-align: center;
    margin-top: 10px;
}

.products-view-list {
    .products-view-buttons-cell {
        padding-right: 0rem;
        width: 100px;
    }

    .products-view-buttons {
        a {
            margin: 0 auto;
        }
    }
}

.products-view-table {
    .products-view-buttons-cell {
        padding-right: 0rem;
        width: 100px;
    }
}


.brand-txt, .myaccount__wishlist {
    .products-view-list {
        .products-view-item > .products-view-pictures,
        .products-view-info,
        .products-view-price-block,
        .products-view-pictures,
        .products-view-item > .hover-area {
            display: none !important;
        }

        .products-view-labels {
            z-index: 1;
        }

        .products-view-buttons-cell {
            padding-right: 0rem;
            width: 100px;
        }

        .products-view-block-hidden {
            display: block !important;
            width: 100%;

            &-descr {
                margin-top: 1rem;
                display: flex !important;

                &-left {
                    width: 75%;
                    padding-right: 10px;
                }

                &-right {
                    width: 300px;

                    .products-view-price-block {
                        display: flex !important;
                        align-items: center;
                        margin-bottom: 0.625rem;

                        .products-view-buttons {
                            margin-top: 0;
                        }
                    }
                }
            }

            .products-view-pictures, .products-view-category, .products-view-name {
                display: block !important;
            }

            .products-view-pictures {
                position: relative;
            }

            .products-view-picture-link {
                max-width: 274px;
            }
        }

        .products-view-block .products-view-item {
            padding: 0;
        }
    }

    .products-view-table {
        .products-view-item > .products-view-pictures,
        .products-view-info,
        .products-view-price-block,
        .products-view-pictures,
        .products-view-item > .hover-area {
            display: none !important;
        }

        .products-view-pictures {
            flex-basis: 100% !important;
            max-width: 100% !important;
        }

        .products-view-labels {
            position: absolute;
            top: 0.825rem;
            left: 0.625rem;
            z-index: 1;
        }

        .products-view-buttons-cell {
            padding-right: 0rem;
            width: 100px;
        }

        .products-view-block-hidden {
            display: block !important;
            width: 100%;

            &-descr {
                margin-top: 1rem;
                display: flex !important;

                &-left {
                    width: 75%;
                    padding-right: 10px;
                }

                &-right {
                    width: 300px;

                    .products-view-price-block {
                        display: flex !important;
                        justify-content: space-around;
                        align-items: center;
                        margin-bottom: 0.625rem;

                        .products-view-buttons {
                            margin-top: 0;
                        }

                        .products-view-price {
                            padding-top: 0;
                        }
                    }
                }
            }

            .products-view-pictures, .products-view-category, .products-view-name {
                display: block !important;
            }

            .products-view-pictures {
                position: relative;
            }

            .products-view-picture-link {
                max-width: 274px;
            }
        }

        .products-view-block .products-view-item {
            padding: 0;
        }
    }
}

.myaccount__wishlist {
    .products-view-list .products-view-block {
        padding: .825rem 0;
    } 
    
    .products-view-item {

        .products-view-category,
        .products-view-name,
        .products-view-pictures,
        .products-view-info,
        .products-view-price-block,
        .hover-area {
            display: none !important;
        }

        .products-view-block {
            padding: 0;
        }

        .products-view-block-hidden {
            width: 100%;
            display: block !important;

            .products-view-category,
            .products-view-name,
            .products-view-pictures,
            .products-view-info,
            .products-view-price-block,
            .hover-area {
                display: block !important;
            }

            .products-view-block-hidden-descr-right .products-view-price-block {
                display: flex !important;
            }

            .products-view-pictures {
                position: relative;
            }

            .products-view-block-hidden-descr {
                display: flex;

                &-left {
                    width: 75%;
                    padding-right: 10px;
                }

                &-right {
                    width: 300px;

                    .product-view-price {
                        text-align: right;
                        margin-top: 16px;
                    }

                    .products-view-buttons-cell {
                        .products-view-buttons {
                            margin: 0 auto;
                        }
                    }

                    .hover-area {
                        margin-top: 20px;
                        padding-top: 10px;

                        .hover-area-wishlist {
                            display: none;
                        }

                        .hover-area-compare {
                            margin: 0 auto;
                        }
                    }
                }

                .products-view-description {
                    color: #7d7d7d;
                    font-size: 13px;
                    line-height: 1.75em;
                    height: 69px;
                    overflow: hidden;
                }
            }
        }
    }
}